import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PaperItem from './PaperItem';
import { CustomButton, SourceInfo, deletePaperFromLibrary, handleChatClick } from '../../authService';
import { exportToBibTeX, exportToCSV, downloadFile } from '../../HelperUtils/ExportUtils';
import { toast } from 'sonner';
import { CustomColumnsManager, useCustomColumns } from '../customColumn/CustomColumnsManager';
import { useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import { useProcessing } from '../../contexts/processingContext';
import { ProFeatureButton } from "@/components/ProFeatureButton";
import { useAuth } from '@/contexts/AuthContext';

import {
    ChevronDown,
    MessageCircle,
    Table,
    List,
    X,
    Search,
    Download,
    CheckSquare,
    Square,
    Plus,
    FileText,
    Sliders,
    Info,
} from 'lucide-react';
import ReusableTable from '../AnswerTable/ReusableTable';
import PaperInfoPage from '@/pages/FullScreenPaperView';
import { GlobalCitationStyleControl } from '@/contexts/citationContext';

interface PaperListProps {
    papers: SourceInfo[];
    handleCheckboxChange: (sourceId: string) => void;
    handleSave?: (givenId: null | string) => void;
    isLoading: boolean;
    emptyMessage: string;
    selectedSourceIds: string[];
    id: string;
    referencesIdNumbers?: { [id: string]: number };
    markedSummaries?: { [id: string]: string };
    showExtractedSource?: boolean;
    query_hash?: string;
    onPaperUpdate?: (updatedPaper: SourceInfo) => void;
    customButtonsPaperItem?: CustomButton[];
    compactUi?: boolean;
    pendingCitationId?: string | null;
    onCitationResolved?: () => void;
}

const ITEMS_PER_PAGE = 10;

const PaperList: React.FC<PaperListProps> = ({
    papers,
    handleCheckboxChange,
    handleSave,
    isLoading,
    emptyMessage,
    selectedSourceIds,
    id,
    referencesIdNumbers,
    markedSummaries,
    showExtractedSource = false,
    query_hash,
    onPaperUpdate,
    customButtonsPaperItem = [], // Default to empty array
    compactUi = false,
    pendingCitationId = null,
    onCitationResolved,
}) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sortMethod, setSortMethod] = useState<'default' | 'date' | 'citations'>('default');
    const [searchTerm, setSearchTerm] = useState('');
    const [listMode, setListMode] = useState<boolean>(false);
    const [exportMenuOpen, setExportMenuOpen] = useState<boolean>(false);
    const [sortMenuOpen, setSortMenuOpen] = useState<boolean>(false);
    const [allSelected, setAllSelected] = useState<boolean>(false);

    // New state and effect for tracking PDF selection
    const [allPdfSelected, setAllPdfSelected] = useState<boolean>(false);

    const [showSelectionHint, setShowSelectionHint] = useState(true);

    const {
        customColumns,
        customColumnData,
        loadingColumns,
        updateColumnsAndData,
    } = useCustomColumns();

    const { addTask, removeTask } = useProcessing();

    const { isPro } = useAuth();

    const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    }, []);

    const handleChat = async (givenId: string | null = null) => {
        if (selectedSourceIds.length === 0 && !givenId) {
            toast.error('Please select at least one paper to chat with.');
            return;
        }

        const taskId = addTask('Processing Selected Papers');

        handleChatClick(
            givenId ? [givenId] : selectedSourceIds,
            id!,
            navigate,
            (response) => {
                removeTask(taskId);
                toast.success(response);
            },
            (error) => {
                removeTask(taskId);
                toast.error(error);
            },
            papers
        );
    };

    const handleDeletePaper = async (paperId: string) => {
        const taskId = addTask('Deleting Paper');
        try {
            await deletePaperFromLibrary(paperId, id);
            removeTask(taskId);
            toast.success('Paper deleted successfully');
            window.location.reload();
        } catch (error) {
            removeTask(taskId);
            toast.error('Failed to delete paper');
        }
    };

    const handleSummaryClick = () => {
        if (selectedSourceIds.length === 0) {
            toast.error('Please select at least one source to summarize.');
            return;
        }
        navigate(`/${id}/answerthis/answer/summary/${query_hash}`, {
            state: { selectedSources: selectedSourceIds },
        });
    };

    const sortedPapers = useMemo(() => {
        const newSortedPapers = [...papers];
        if (sortMethod === 'date') {
            newSortedPapers.sort(
                (a, b) => +new Date(a.date_of_publication) - +new Date(b.date_of_publication)
            );
        } else if (sortMethod === 'citations') {
            newSortedPapers.sort((a, b) => b.citations - a.citations);
        }
        return newSortedPapers;
    }, [papers, sortMethod]);

    const fuse = useMemo(() => new Fuse(sortedPapers, {
        keys: [
            { name: 'title', weight: 1 },
            { name: 'author', weight: 0.5 },
            { name: 'journal', weight: 0.5 },
            { name: 'abstract', weight: 0.5 },
        ],
        threshold: 0.4,
        ignoreLocation: true,
        ignoreFieldNorm: true,
        // We want to match parts of words in titles
        minMatchCharLength: 2,  // But avoid too-short matches
        // Always good to sort by relevance
        shouldSort: true,

        // Include score for debugging if needed
        includeScore: true,

        // For paper titles, matching anywhere is fine
        distance: 1000,

        // Don't need extended search for basic paper search
        useExtendedSearch: false,

        // Case shouldn't matter for academic search
        isCaseSensitive: false,

        // We want all possible matches for papers
        findAllMatches: true,
    }), [sortedPapers]);

    const filteredPapers = useMemo(() => {
        if (!searchTerm) return sortedPapers;
        return fuse.search(searchTerm).map(result => result.item);
    }, [searchTerm, sortedPapers, fuse]);

    const totalPages = useMemo(() =>
        Math.ceil(filteredPapers.length / ITEMS_PER_PAGE)
        , [filteredPapers]);
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const getCurrentPageItems = () => {
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const endIndex = startIndex + ITEMS_PER_PAGE;
        const items = filteredPapers.slice(startIndex, endIndex);

        if (!isPro) {
            return items.slice(0, 3);
        }

        return items;
    };

    const allPaperIds = useMemo(() => papers.map((paper) => paper.id), [papers]);

    // Compute which papers have PDF
    const allPdfPaperIds = useMemo(() => {
        return papers
            .filter(p => p.pdf_path && p.pdf_path !== '' && p.pdf_path !== 'None')
            .map(p => p.id);
    }, [papers]);

    const exportFile = (type: 'csv' | 'bib') => {
        const selectedPapers = papers.filter((paper) => selectedSourceIds.includes(paper.id));
        if (selectedPapers.length === 0) {
            toast.error('Please select at least one paper to export.');
            return;
        }
        if (type === 'csv') {
            const csvData = exportToCSV(selectedPapers, customColumns, customColumnData);
            downloadFile(csvData, 'papers.csv', 'text/csv');
        } else {
            const bibData = exportToBibTeX(selectedPapers);
            downloadFile(bibData, 'papers.bib', 'text/plain');
        }
    };

    // Handle Select All
    const toggleSelectAll = () => {
        if (allSelected) {
            // Deselect all
            allPaperIds.forEach((id) => handleCheckboxChange(id));
        } else {
            // Select all
            allPaperIds.forEach((id) => {
                if (!selectedSourceIds.includes(id)) {
                    handleCheckboxChange(id);
                }
            });
        }
        setAllSelected(!allSelected);
    };

    // Handle Select All PDFs
    const toggleSelectAllPdf = () => {
        if (allPdfSelected) {
            // Deselect all PDFs
            allPdfPaperIds.forEach((id) => handleCheckboxChange(id));
        } else {
            // Select all PDFs
            allPdfPaperIds.forEach((id) => {
                if (!selectedSourceIds.includes(id)) {
                    handleCheckboxChange(id);
                }
            });
        }
        setAllPdfSelected(!allPdfSelected);
    };

    // Update allSelected state when selectedSourceIds change
    useEffect(() => {
        setAllSelected(selectedSourceIds.length === allPaperIds.length);
    }, [selectedSourceIds, allPaperIds]);

    // Update allPdfSelected state based on current selections
    useEffect(() => {
        setAllPdfSelected(allPdfPaperIds.length > 0 && allPdfPaperIds.every(id => selectedSourceIds.includes(id)));
    }, [selectedSourceIds, allPdfPaperIds]);

    useEffect(() => {
        if (selectedSourceIds.length > 0) {
            setShowSelectionHint(false);
        }
    }, [selectedSourceIds]);

    const [showPaperView, setShowPaperView] = useState(false);
    const [currentPaperId, setCurrentPaperId] = useState<string | null>(null);
    const handlePaperView = (paperId: string) => {
        setCurrentPaperId(paperId);
        setShowPaperView(true);
    }

    // Find which page a citation is on
    const findCitationPage = useCallback((citationId: string) => {
        // First check if the citation exists in any paper section
        const sectionMap = new Map<string, number>();

        // Map all section IDs to their paper index
        papers.forEach((paper, index) => {
            paper.sections.forEach(section => {
                sectionMap.set(section.id, index);
            });
        });

        // If the citation ID is in our map, find which page it's on
        if (sectionMap.has(citationId)) {
            const paperIndex = sectionMap.get(citationId)!;
            return Math.ceil((paperIndex + 1) / ITEMS_PER_PAGE);
        }

        return null;
    }, [papers]);

    // Handle scrolling to citation after page navigation
    useEffect(() => {
        if (pendingCitationId) {
            // Check if the element is now in the DOM
            const targetElement = document.getElementById(pendingCitationId);

            if (targetElement) {
                // Found the element, scroll to it
                document.querySelectorAll('.selected').forEach((el) => {
                    el.classList.remove('selected');
                });

                const parentSection = targetElement.closest('.paper-section');
                if (parentSection) {
                    parentSection.scrollIntoView({ behavior: 'smooth' });
                    targetElement.classList.add('selected');
                } else {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                    targetElement.classList.add('selected');
                }

                // Clear the pending citation
                if (onCitationResolved) onCitationResolved();
            } else {
                // Element still not found, try to find which page it's on
                const citationPage = findCitationPage(pendingCitationId);

                if (citationPage && citationPage !== currentPage) {
                    // Navigate to the correct page
                    setCurrentPage(citationPage);
                    // The useEffect will run again after page change and try to find the element
                }
            }
        }
    }, [pendingCitationId, currentPage, findCitationPage, onCitationResolved]);

    return (
        (showPaperView ? <PaperInfoPage providedPaperId={currentPaperId!} customBackAction={() => setShowPaperView(false)} customBackButtonText='Back to Sources' /> : <div className="font-Manrope">
            {/* Remove the old processing indicator since we're using the global one now */}
            {isLoading ? (
                <p className="text-xl my-7 mb-3 px-6">
                    Loading papers... <div className="spinner"></div>
                </p>
            ) : papers.length === 0 ? (
                <p className="text-lg my-7 mb-3 px-6">{emptyMessage}</p>
            ) : (
                <>
                    {/* Toolbar */}
                    <div >
                        <div className="flex flex-col p-4 space-y-4">
                            {/* Top Row */}
                            <div className="flex flex-col sm:flex-row items-center gap-2 w-full">
                                {/* Select All */}
                                <div className="relative w-full sm:w-auto flex gap-2" title="Select or deselect all papers">
                                    <button
                                        className="flex items-center px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition w-full sm:w-auto"
                                        onClick={toggleSelectAll}
                                    >
                                        {allSelected ? (
                                            <>
                                                <CheckSquare className="w-5 h-5 mr-1" />
                                            </>
                                        ) : (
                                            <>
                                                <Square className="w-5 h-5 mr-1" />
                                                <span>All</span>
                                            </>
                                        )}
                                    </button>
                                    {/* Select All PDFs Button */}
                                    <button
                                        className="flex items-center px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition w-full sm:w-auto"
                                        onClick={toggleSelectAllPdf}
                                    >
                                        {allPdfSelected ? (
                                            <>
                                                <CheckSquare className="w-5 h-5 mr-1" />
                                            </>
                                        ) : (
                                            <>
                                                <Square className="w-5 h-5 mr-1" />
                                                <span>PDF</span>
                                            </>
                                        )}
                                    </button>
                                </div>
                                {/* Search Input */}
                                <div className="relative w-full">
                                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="w-full pl-10 pr-3 py-2 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-brandMaroon"
                                        onChange={handleSearchChange}
                                        value={searchTerm}
                                    />
                                    {searchTerm && (
                                        <button
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                                            onClick={() => setSearchTerm('')}
                                        >
                                            <X className="w-4 h-4" />
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Bottom Row */}
                            <div className="flex flex-wrap items-center gap-2 w-full">
                                {/* View Toggle */}
                                {!compactUi && <button
                                    className="flex items-center px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition flex-grow sm:flex-grow-0"
                                    onClick={() => setListMode(!listMode)}
                                >
                                    {listMode ? (
                                        <>
                                            <List className="w-5 h-5 mr-1" />
                                            <span>List View</span>
                                        </>
                                    ) : (
                                        <>
                                            <Table className="w-5 h-5 mr-1" />
                                            <span>Table View</span>
                                        </>
                                    )}
                                </button>}

                                {/* Sort Selector */}
                                <div className="relative flex-grow sm:flex-grow-0">
                                    <button
                                        className="flex items-center px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition w-full"
                                        onClick={() => setSortMenuOpen(!sortMenuOpen)}
                                    >
                                        <Sliders className="w-5 h-5 mr-1" />
                                        {!compactUi && <span>Sort</span>}
                                        <ChevronDown className="w-4 h-4 ml-1" />
                                    </button>
                                    {sortMenuOpen && (
                                        <div className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg z-50">
                                            <button
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                onClick={() => {
                                                    setSortMethod('default');
                                                    setSortMenuOpen(false);
                                                }}
                                            >
                                                Default Sort
                                            </button>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                onClick={() => {
                                                    setSortMethod('date');
                                                    setSortMenuOpen(false);
                                                }}
                                            >
                                                Sort by Date
                                            </button>
                                            <button
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                                onClick={() => {
                                                    setSortMethod('citations');
                                                    setSortMenuOpen(false);
                                                }}
                                            >
                                                Sort by Citations
                                            </button>
                                        </div>
                                    )}
                                </div>

                                {/* Custom Columns */}
                                <CustomColumnsManager
                                    sourceIds={allPaperIds}
                                    onColumnsUpdate={updateColumnsAndData}
                                />
                                <GlobalCitationStyleControl compactUi={true} />


                                {/* Export Button */}
                                <div className="relative flex-grow sm:flex-grow-0">
                                    <button
                                        className="flex items-center px-3 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition w-full"
                                        onClick={() => setExportMenuOpen(!exportMenuOpen)}
                                    >
                                        <Download className="w-5 h-5 mr-1" />
                                        {!compactUi && <span>Export</span>}
                                        <ChevronDown className="w-4 h-4 ml-1" />
                                    </button>
                                    {exportMenuOpen && (
                                        <div className="absolute right-0 mt-2 w-40 bg-white border rounded-md shadow-lg">
                                            <ProFeatureButton
                                                onClick={() => {
                                                    exportFile('csv');
                                                    setExportMenuOpen(false);
                                                }}
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Export to CSV
                                            </ProFeatureButton>
                                            <ProFeatureButton
                                                onClick={() => {
                                                    exportFile('bib');
                                                    setExportMenuOpen(false);
                                                }}
                                                className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                                            >
                                                Export to BibTeX
                                            </ProFeatureButton>
                                        </div>
                                    )}

                                </div>

                            </div>

                        </div>
                    </div>

                    {/* Selection Hint */}
                    {showSelectionHint && (
                        <div className="bg-lightMaroon border-l-4 border-brandMaroon  p-4 mb-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <Info className="h-5 w-5 " />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm">
                                        Select papers to perform actions like Chat, Summarize, or Export.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Conditional Action Buttons */}
                    {selectedSourceIds.length > 0 && (
                        <div className="flex flex-wrap items-center justify-center gap-2 p-4 bg-gray-50 border-b">
                            {handleSave && (
                                <button
                                    className="flex items-center px-3 py-2 bg-brandMaroon text-white rounded-md hover:bg-brandMaroonDark transition w-full sm:w-auto"
                                    onClick={() => handleSave(null)}
                                >
                                    <Plus className="w-5 h-5 mr-1" />
                                    {!compactUi && <span>Add to Library</span>}
                                </button>
                            )}
                            <button
                                className="flex items-center px-3 py-2 bg-brandMaroon text-white rounded-md hover:bg-brandMaroonDark transition w-full sm:w-auto"
                                onClick={() => handleChat()}
                            >
                                <MessageCircle className="w-5 h-5 mr-1" />
                                <span>Chat</span>
                            </button>
                            {query_hash && (
                                <button
                                    className="flex items-center px-3 py-2 bg-brandMaroon text-white rounded-md hover:bg-brandMaroonDark transition w-full sm:w-auto"
                                    onClick={handleSummaryClick}
                                >
                                    <FileText className="w-5 h-5 mr-1" />
                                    <span>Summarize</span>
                                </button>
                            )}
                        </div>
                    )}

                    {/* Paper List */}
                    {listMode ? (
                        <div className="mt-5">
                            <ReusableTable
                                data={getCurrentPageItems()}
                                selectedSourceIds={selectedSourceIds}
                                handleCheckboxChange={handleCheckboxChange}
                                referencesIdNumbers={referencesIdNumbers}
                                markedSummaries={markedSummaries}
                                customColumns={customColumns}
                                customColumnData={customColumnData}
                                loadingColumns={loadingColumns}
                                showExtractedSource={showExtractedSource}
                                showCitation={true}
                                handleTitleClick={handlePaperView}
                            />
                            {!isPro && filteredPapers.length > 3 && (
                                <div className="mt-4 mb-5 p-4 bg-gray-50 border rounded-lg text-center">
                                    <p className="text-gray-700 mb-2">
                                        Viewing 3 out of {filteredPapers.length} papers
                                    </p>
                                    <button
                                        onClick={() => navigate(`/${id}/pricing`)}
                                        className="inline-flex items-center px-4 py-2 bg-white border border-gray-200 rounded-md hover:bg-gray-50"
                                    >
                                        View all papers
                                        <span className="ml-2 px-2 py-0.5 text-xs rounded-sm bg-red-50 text-red-900 font-medium">
                                            UPGRADE
                                        </span>
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            {getCurrentPageItems()
                                .filter(
                                    (paper, index, self) =>
                                        self.findIndex((p) => p.id === paper.id) === index
                                )
                                .map((paper) => (
                                    <PaperItem
                                        key={paper.id}
                                        paper={paper}
                                        isLibrary={!handleSave}
                                        handleSave={handleSave}
                                        handleChat={handleChat}
                                        handleDelete={handleDeletePaper}
                                        selectedSourceIds={selectedSourceIds}
                                        handleCheckboxChange={handleCheckboxChange}
                                        customColumns={customColumns}
                                        customColumnData={customColumnData}
                                        loadingColumns={loadingColumns}
                                        markedSummary={markedSummaries?.[paper.id]}
                                        handleTitleClick={handlePaperView}
                                        onPaperUpdate={onPaperUpdate}
                                        projectId={id}
                                        customButtons={customButtonsPaperItem}
                                        compactUi={compactUi}
                                    />
                                ))}
                            {!isPro && filteredPapers.length > 3 && (
                                <div className="mt-4 mb-5 p-4 bg-gray-50 border rounded-lg text-center">
                                    <p className="text-gray-700 mb-2">
                                        Viewing 3 out of {filteredPapers.length} papers
                                    </p>
                                    <button
                                        onClick={() => navigate(`/${id}/pricing`)}
                                        className="inline-flex items-center px-4 py-2 bg-white border border-gray-200 rounded-md hover:bg-gray-50"
                                    >
                                        View all papers
                                        <span className="ml-2 px-2 py-0.5 text-xs rounded-sm bg-red-50 text-red-900 font-medium">
                                            UPGRADE
                                        </span>
                                    </button>
                                </div>
                            )}
                        </>
                    )}

                    {/* Pagination - Only show if pro or less than 3 papers */}
                    {(isPro || filteredPapers.length <= 3) && (
                        <div className="flex justify-center items-center gap-4 my-5">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`px-3 py-2 rounded-md ${currentPage === 1
                                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                    }`}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className={`px-3 py-2 rounded-md ${currentPage === totalPages
                                    ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
                                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                    }`}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </>
            )}
        </div>)
    );
};

export default PaperList;
