import React, { useRef, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  sendQuery,
  Filters,
  defaultFilters,
  UploadPdfResponse,
  RecentSearch,
  storeQueryStatus
} from "../../authService";
import FileUploadBar from "../../components/FileUploadBar";
import PromptHelper from "../../components/promptHelper";
import { toast } from "sonner";
import { Search } from "lucide-react";

import SeoComp from "../../components/SeoComp";
import { useSidebar } from "../../contexts/sidebarContext";
import FilterSidebar from "../../components/filters/FiltersSidebar";
import UploadedFilesList from "./UploadFilesList";
import { useAuth } from "../../contexts/AuthContext";
import "./LiteratureReview.css";
import Loading from "../../components/Loading";
import { useContentHeight } from "../../utils/tabsHeightCalculator";
import AdvancedSearchBar from "@/components/searchInput/AdvancedSearchBar";
import RecentSearchesList from "../../components/MainUploadPage/RecentSearchesList";
import SearchBar from "@/components/searchInput/SearchBar";
import documentIcon from "/img/document-icon-gray.svg";

interface LiteratureReviewProps {
  showOnlySearchBar?: boolean;
  onQueryChange?: (query: string) => void;
  initialQuery?: string;
}

interface TrySearchForProps {
  handleExampleQuestionClick: (question: string) => void;
}

function TrySearchFor({ handleExampleQuestionClick }: TrySearchForProps) {
  const exampleQuestions = [
    "How does climate change impact biodiversity?",
    "Why are aging Covid patients more susceptible to severe complications?",
    "How does social media affect the college selection process?",

  ];

  return (
    <div className="p-3">
      <div className="flex  flex-col md:flex-row justify-start">
        <p className="text-sm font-medium text-gray-700 mr-3 mb-2 md:mb-0">Try searching for:</p>
        <div className="flex flex-wrap gap-2 overflow-x-auto scrollbar-hide">
          {exampleQuestions.map((question, index) => (
            <button
              key={index}
              className="flex-shrink-0 flex items-center px-3 py-1 
                        bg-white border border-gray-200 rounded-lg text-xs text-gray-700
                        hover:border-brandMaroon hover:text-brandMaroon
                        transition-colors duration-200 shadow-sm whitespace-nowrap"
              onClick={() => handleExampleQuestionClick(question)}
            >
              <Search className="w-3 h-3 text-gray-500 mr-2 flex-shrink-0" />
              <span className="truncate max-w-[150px]">{question}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

const LiteratureReview: React.FC<LiteratureReviewProps> = ({
  showOnlySearchBar = false,
  onQueryChange,
  initialQuery = ""
}) => {
  const { id } = useParams();
  const { user, checkCredits, removeCredits } = useAuth();
  const { refreshSidebar, recentSearches } = useSidebar();
  const [contentHeight] = useContentHeight();
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [getPapers, setGetPapers] = useState<boolean>(false);
  const [queryText, setQueryText] = useState<string>("");
  const [hasFreeUploads, setHasFreeUploads] = useState<boolean>(false);
  const [searchBarContext, setSearchBarContext] = useState<string>("");
  const [filtersInfo, setFiltersInfo] = useState<Filters>(defaultFilters);
  const navigate = useNavigate();

  const [isSendingQuery, setIsSendingQuery] = useState<boolean>(false);

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [showFileUpload, setShowFileUpload] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  // Determine if user is pro
  const isPro = user?.level === "paid";

  useEffect(() => {
    setQueryText(initialQuery);
  }, [initialQuery]);

  useEffect(() => {
    adjustHeight();
  }, []);

  useEffect(() => {
    setContext();
  }, [user]);

  function promptHelp(value: string) {
    setQueryText(value);
    textareaRef.current?.focus();
  }

  const upload = useCallback(
    async (filesArray: File[]) => {
      if (filesArray.length === 0 && !getPapers) {
        toast.error("Please select files to upload before asking a query.");
        return;
      }

      const toastId = toast.loading("Sending query...");

      try {
        const hasEnoughCredits = checkCredits(id!, getPapers ? 1 : filesArray.length);
        if (!hasEnoughCredits) {
          toast.dismiss(toastId);
          return;
        }

        setIsSendingQuery(true);
        await sendQuery(
          id!,
          queryText,
          getPapers,
          filtersInfo,
          filesArray,
          async (response: UploadPdfResponse) => {
            console.log("Upload response:", response);
            await removeCredits(getPapers ? 1 : filesArray.length);
            setUploadedFiles([]);
            const queryHash = response.query_hash;

            // Store the query status with the current filters
            if (queryHash) {
              storeQueryStatus(queryHash, filtersInfo.answerStyle, true);
            }

            refreshSidebar();
            toast.success("Query sent successfully", { id: toastId });
            navigate(`/${id}/answerthis/answer/${queryHash}`, {
              state: { question: queryText, filters: filtersInfo },
            });
          },
          (errorMessage: string) => {
            console.error(errorMessage);
            toast.error(errorMessage, { id: toastId });
          }
        );

        setIsSubmitDisabled(false);
      } catch (error) {
        setIsSendingQuery(false);
        toast.dismiss(toastId);
        setIsSubmitDisabled(false);
      }
    },
    [queryText, getPapers, filtersInfo, navigate, id, refreshSidebar, checkCredits, removeCredits]
  );

  const handleSubmit = () => {
    console.log("submitting");
    setIsSubmitDisabled(true);
    if (uploadedFiles.length > 0 && queryText.length > 0) {
      console.log("sending files here");
      upload(uploadedFiles);
    } else if (getPapers && queryText.length > 0) {
      console.log("sending query here");
      upload([]);
    } else if (!getPapers && uploadedFiles.length === 0) {
      if (!checkCredits(id!, 1)) {
        setShowFilters(false);
        setIsSubmitDisabled(false);
        return;
      }
    } else if (queryText.length === 0) {
      toast.error("You need to ask a question first");
      setShowFilters(false);
      setIsSubmitDisabled(false);
    }
  };

  const setContext = () => {
    if (user?.level === "trial" && (user?.credits ?? 0) <= 0) {
      setSearchBarContext(
        "No credits left, you need to upgrade to pro. It will also help us continue building answerthis."
      );
      setGetPapers(false);
      setHasFreeUploads(false);
    } else if (uploadedFiles.length > 0) {
      setSearchBarContext(
        `Answering question from: ${uploadedFiles.length} uploaded papers `
      );
      setGetPapers(false);
      setHasFreeUploads(true);
    } else {
      setSearchBarContext(
        ""
      );
      setGetPapers(true);
      setHasFreeUploads(true);
    }
  };

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "60px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQueryText(value);
    if (onQueryChange) {
      onQueryChange(value);
    }
    adjustHeight();
  };

  const handleShowFileUpload = () => {
    setShowFileUpload(!showFileUpload);
  };

  const handleFileChange = (files: File[]) => {
    setUploadedFiles(files);
  };

  useEffect(() => {
    setContext();
    if (uploadedFiles.length > 0) {
      setGetPapers(false);
    } else {
      setGetPapers(true);
    }
  }, [uploadedFiles]);

  const handleExampleQuestionClick = (question: string) => {
    setQueryText(question);
    textareaRef.current?.focus();
  };

  const showFiltersSidebar = () => {
    setShowFilters(!showFilters);
  }
  const handleShowFilters = () => {
    if (uploadedFiles.length > 0) {
      handleSubmit();
    } else {
      if (getPapers) {
        setShowFilters(!showFilters);
      } else {
        handleRedirectPricing();
      }
    }
  };
  const handleRedirectPricing = () => {
    navigate(`/${id}/pricing`)
  }
  const handleRemoveFile = (index: number) => {
    const newFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(newFiles);
  };

  const handleRecentSearchClick = (search: RecentSearch) => {
    const route = search.type === "summary"
      ? `/${id}/answerthis/answer/summary/${search.query_hash}`
      : `/${id}/answerthis/answer/${search.query_hash}`;
    navigate(route);
  };

  const renderAdvancedSearchBar = () => (
    <div className="px-4">
      {searchBarContext && (
        <div className="text-center mb-4">
          <p className={`text-sm ${hasFreeUploads ? "text-gray-600" : "text-red-600"}`}>
            {searchBarContext}
          </p>
        </div>
      )}

      <div className="hidden md:block">
        <AdvancedSearchBar
          handleInputChange={handleTextareaChange}
          handleSearch={handleSubmit}
          query={queryText}
          placeholder="Ask any research question..."
          disabled={isSubmitDisabled}
          filters={filtersInfo}
          setFilters={setFiltersInfo}
          onUploadPDFClick={handleShowFileUpload}
          hasUploadedFiles={uploadedFiles.length > 0}
          isPro={isPro}
        />

        {/* Deep Research info text - always displayed and center-aligned with top margin */}
        <div className="mt-2 text-xs text-gray-500 text-center px-1">
          <span>Deep Research provides the most comprehensive results but may take longer to complete.</span>
        </div>
      </div>
      <div className="md:hidden">
        <div className="search-bar-and-icons" id="searchbar">
          <SearchBar
            handleInputChange={handleTextareaChange}
            handleSearch={handleShowFilters}
            query={queryText}
            placeholder="Ask or inquire about something..."
            disabled={isSubmitDisabled}
          />
          <div className="help-icons-desktop">
            <p>Upload PDFs</p>
            <img
              src={documentIcon}
              alt="document icon"
              className="help-icon"
              onClick={handleShowFileUpload}
            />
          </div>
        </div>
      </div>
      <TrySearchFor handleExampleQuestionClick={handleExampleQuestionClick} />

      {uploadedFiles.length > 0 && (
        <div className=" bg-gray-50 rounded-lg p-3 border border-gray-200">
          <UploadedFilesList files={uploadedFiles} onRemoveFile={handleRemoveFile} />
        </div>
      )}
    </div>
  );

  // Show recent searches only in full view (when not embedded in HomeUpload)
  const shouldShowRecentSearches = !showOnlySearchBar;

  return (
    <>
      {isSendingQuery ? <Loading question="" /> : (
        <>
          <SeoComp />
          <div
            className={`${showOnlySearchBar ? 'pt-2' : 'mx-auto max-w-4xl pt-14'} bg-white`}
            style={{
              height: showOnlySearchBar ? "auto" : contentHeight,
            }}
          >
            <div className="w-full h-full">
              <div className="w-full text-gray-700 font-Manrope">
                {!showOnlySearchBar && <p className="px-2 text-gray-700 font-Manrope mb-2 text-xl">Literature Review</p>}
                {renderAdvancedSearchBar()}
                <div className="mx-4">
                  <PromptHelper onSelectPrompt={promptHelp} />
                </div>

                {/* Recent Searches Section - only show in full view */}
                {shouldShowRecentSearches && (
                  <RecentSearchesList
                    searches={recentSearches}
                    onSearchClick={handleRecentSearchClick}
                  />
                )}
              </div>
            </div>
          </div>
          <FilterSidebar
            isOpen={showFilters && getPapers}
            filters={filtersInfo}
            setFilters={setFiltersInfo}
            onClose={showFiltersSidebar}
            onApply={handleSubmit}
          />
          <FileUploadBar
            onFilesChange={handleFileChange}
            files={uploadedFiles}
            show={showFileUpload}
            reverseShow={handleShowFileUpload}
          />
        </>
      )}
    </>
  );
}

export default LiteratureReview;











