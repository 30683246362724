import React from 'react';
import parse from 'html-react-parser';
import CollapsibleReferences from './AnswerTable/CollapsibleReferences';
import CitationManager from '../pages/Answer/CitationManager';
import { AnswerReview } from '../pages/Answer/AnswerReview';
import { FiltersAnswer } from '../pages/Answer/AnswerFilters';
import { SourceInfo, Filters, AnswerObject } from '../authService';

interface AnswerViewProps {
  answer: {
    formattedAnswer: string;
    answerObject: AnswerObject;
    papersArraySorted: SourceInfo[];
  };
  queryHash: string;
  id: string;
  answerFilters?: Filters | null;
  onAnswerUpdate: (updatedAnswer: string) => void;
  answerIndex: number;
  compactUi?: boolean;
}

const AnswerView: React.FC<AnswerViewProps> = ({
  answer,
  queryHash,
  id,
  answerFilters = null,
  onAnswerUpdate,
  answerIndex,
  compactUi = false,
}) => {
  const removeReferences = (text: string): string => {
    const parts = text.split('References:');
    return parts[0].trim();
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <div id="answerText" className="prose max-w-none text-[0.9rem] leading-8">
        {parse(removeReferences(answer.formattedAnswer))}
        <CollapsibleReferences answer={answer.formattedAnswer} />
        <div className="mt-8">
          <CitationManager
            sources={answer.papersArraySorted}
            answer={answer.formattedAnswer}
            onAnswerUpdate={onAnswerUpdate}
          />
        </div>
      </div>
      <div className="mt-4">
        <AnswerReview queryHash={queryHash} />
      </div>
      {answerFilters && (
        <FiltersAnswer
          id={id}
          answerFilters={answerFilters}
          answerObject={answer.answerObject}
          question={answer.answerObject.query}
          answerIndex={answerIndex}
          compactUi={compactUi}
        />
      )}
    </div>
  );
};

export default AnswerView;