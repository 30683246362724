import React, { useRef, useEffect, useState } from 'react';
import { SearchIcon, Sliders, Files } from 'lucide-react';
import { Filters, AnswerStyle } from '../../authService';
import {
    Popover,
    PopoverContent
} from "@/components/ui/popover";
import { Switch } from '@/components/ui/switch';
import FilterSidebar from '../filters/FiltersSidebar';
import { useAuth } from '../../contexts/AuthContext';


interface AdvancedSearchBarProps {
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleSearch: () => Promise<void> | void;
    query: string;
    placeholder?: string;
    disabled?: boolean;
    filters: Filters;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
    onUploadPDFClick?: () => void;
    hasUploadedFiles?: boolean;
    isPro?: boolean;
}

export function AdvancedSearchBar({
    handleInputChange,
    handleSearch,
    query,
    placeholder = "Ask any research question...",
    filters,
    setFilters,
    onUploadPDFClick,
    hasUploadedFiles = false,
    isPro = false
}: AdvancedSearchBarProps) {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [showFullFilters, setShowFullFilters] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { togglePricingPopup } = useAuth();

    const adjustHeight = () => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';

            const maxHeight = window.innerWidth <= 768
                ? window.innerHeight * 0.3
                : window.innerHeight * 0.15;

            const newHeight = Math.max(
                48,
                Math.min(inputRef.current.scrollHeight, maxHeight)
            );

            inputRef.current.style.height = `${newHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [query]);

    useEffect(() => {
        const handleResize = () => adjustHeight();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleTextareaInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        handleInputChange(event);
        adjustHeight();
    };

    const toggleSourceFilter = (source: string) => {
        setFilters(prev => {
            const isCurrentlySelected = prev.database?.includes(source) || false;
            let newDatabase = prev.database ? [...prev.database] : [];
            let newResearchPapersFilter = prev.researchPapersFilter ? [...prev.researchPapersFilter] : [];
            let currentAnswerStyle = prev.answerStyle;

            if (isCurrentlySelected) {
                // Remove the source
                newDatabase = newDatabase.filter(db => db !== source);
                if (source === "research papers") {
                    // Clear subfilters when research papers is removed
                    newResearchPapersFilter = [];
                }
            } else {
                // Add the source
                newDatabase.push(source);
                if (source === "research papers") {
                    // Add default subfilters if none exist
                    if (newResearchPapersFilter.length === 0) {
                        newResearchPapersFilter = ["semanticscholar", "openalex"];
                    }
                }
            }

            // Check if answer style needs reset (after database update)
            const needsSource = currentAnswerStyle === 'literature_review' || currentAnswerStyle === 'deep_research';
            const hasSource = newDatabase.includes("internet") || newDatabase.includes("research papers");

            if (needsSource && !hasSource) {
                // If current style needs a source, but none are selected anymore, reset to assistant
                currentAnswerStyle = 'research_assistant';
            }

            return {
                ...prev, // Spread previous state first
                database: newDatabase,
                researchPapersFilter: newResearchPapersFilter,
                answerStyle: currentAnswerStyle // Use the potentially updated style
            };
        });
    };

    const handleSourceFilterChange = (source: string) => {
        // If files are uploaded, don't allow changing sources
        if (hasUploadedFiles) {
            return;
        }

        // If trying to add "internet" when deep_research is selected, don't allow it
        if (source === "internet" && filters.answerStyle === 'deep_research') {
            // Optionally show a notification or dialog explaining why this isn't allowed
            return;
        }

        toggleSourceFilter(source);

        // If removing "research papers" when deep_research is selected, switch to another answer style
        if (source === "research papers" && filters.answerStyle === 'deep_research') {
            setFilters(prev => ({
                ...prev,
                answerStyle: 'research_assistant'
            }));
        }
    };

    const handleSubFilterToggle = (subfilter: "semanticscholar" | "openalex" | "pubmed" | "arxiv") => {
        // If files are uploaded, don't allow changing subfilters
        if (hasUploadedFiles) {
            return;
        }

        // If in deep research mode, only allow semanticscholar
        if (filters.answerStyle === 'deep_research') {
            if (subfilter === "semanticscholar") {
                // Don't allow removing semanticscholar in deep research mode
                if (filters.researchPapersFilter?.includes("semanticscholar")) {
                    return;
                } else {
                    // Add semanticscholar (this should not happen in normal operation but handles edge cases)
                    setFilters(prev => ({
                        ...prev,
                        researchPapersFilter: ["semanticscholar"]
                    }));
                }
            } else {
                // Don't allow adding other paper sources in deep research mode
                return;
            }
        } else {
            // Normal behavior for other answer styles
            setFilters(prev => {
                const currentResearchPapersFilter = prev.researchPapersFilter || [];
                const newResearchPapersFilter = currentResearchPapersFilter.includes(subfilter)
                    ? currentResearchPapersFilter.filter(f => f !== subfilter)
                    : [...currentResearchPapersFilter, subfilter];

                let newDatabase = [...(prev.database || [])];
                const requiresResearchPapers = newResearchPapersFilter.length > 0;

                // Update "research papers" in database based on subfilters
                if (requiresResearchPapers && !newDatabase.includes("research papers")) {
                    newDatabase.push("research papers");
                } else if (!requiresResearchPapers && newDatabase.includes("research papers")) {
                    newDatabase = newDatabase.filter(db => db !== "research papers");
                }

                // Check if answer style needs reset
                let currentAnswerStyle = prev.answerStyle;
                const needsSource = currentAnswerStyle === 'literature_review' || currentAnswerStyle === 'deep_research';
                const hasSource = newDatabase.includes("internet") || newDatabase.includes("research papers");

                if (needsSource && !hasSource) {
                    currentAnswerStyle = 'research_assistant';
                }

                return {
                    ...prev,
                    researchPapersFilter: newResearchPapersFilter,
                    database: newDatabase,
                    answerStyle: currentAnswerStyle
                };
            });
        }
    };

    const openAllFilters = () => {
        setShowFullFilters(true);
    };

    const closeAllFilters = () => {
        setShowFullFilters(false);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // If we know we're submitting a query, we can set a temporary status in localStorage
        // This will be overwritten when the actual query is processed
        const tempId = `temp_${Date.now()}`;
        sessionStorage.setItem('last_query_temp_id', tempId);
        sessionStorage.setItem('last_query_filters', JSON.stringify(filters));

        handleSearch();
    };

    // Handle changing the answer style
    const handleAnswerStyleChange = (newStyle: AnswerStyle) => {
        // When files are uploaded, always use research_assistant mode
        if (hasUploadedFiles && newStyle !== 'research_assistant') {
            // Only research_assistant is allowed with uploaded files
            return;
        }

        // If trying to select deep_research as non-pro user, show pricing popup instead
        if (newStyle === 'deep_research' && !isPro) {
            // Get the project ID from the URL if needed
            const urlPath = window.location.pathname;
            const projectId = urlPath.split('/')[1];

            // Open the pricing popup
            togglePricingPopup(projectId);
            return;
        }

        setFilters(prev => {
            let newDatabase = [...(prev.database || [])];
            let newResearchPapersFilter = [...(prev.researchPapersFilter || [])];

            // If switching to deep_research
            if (newStyle === 'deep_research') {
                // For deep research, only allow research papers as source
                // Remove internet if it exists
                newDatabase = newDatabase.filter(source => source !== "internet");

                // Ensure research papers is included
                if (!newDatabase.includes("research papers")) {
                    newDatabase.push("research papers");
                }

                // For deep research, only use Semantic Scholar
                newResearchPapersFilter = ["semanticscholar"];
            }
            // If switching to literature_review, ensure at least one source is selected
            else if (newStyle === 'literature_review' && !newDatabase.includes("internet") && !newDatabase.includes("research papers")) {
                // Default to adding 'research papers' if no source is selected
                newDatabase.push("research papers");
                // Ensure default subfilters for research papers are added if needed
                if (newResearchPapersFilter.length === 0) {
                    newResearchPapersFilter = ["semanticscholar", "openalex"];
                }
            }

            return {
                ...prev,
                answerStyle: newStyle,
                database: newDatabase,
                researchPapersFilter: newResearchPapersFilter
            };
        });
    };

    // Define the available answer styles and their labels
    const answerStyleOptions: { value: AnswerStyle; label: string, icon?: React.ReactNode, proOnly?: boolean }[] = [
        { value: 'research_assistant', label: 'Assistant', icon: <svg className="mr-1 h-3 w-3" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0012 11z" clipRule="evenodd" /></svg> },
        { value: 'literature_review', label: 'Literature Review', icon: <svg className="mr-1 h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg> },
        { value: 'deep_research', label: 'Deep Research', icon: <svg className="mr-1 h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.21 15.89A10 10 0 118 2.83M22 12A10 10 0 0012 2v10z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg>, proOnly: true },
    ];

    return (
        <div className="w-full max-w-8xl mx-auto">
            <form
                className="w-full"
                action="submit"
                onSubmit={handleSubmit}
            >
                {/* Add gradient glow effect */}
                <div className="relative group">
                    {/* Strong gradient glow effect */}
                    <div className="absolute -inset-1 bg-gradient-to-r from-brandMaroon/50 via-purple-500/50 to-brandMaroon/50 rounded-2xl blur-lg opacity-70 group-hover:opacity-100 transition-opacity duration-200" />

                    {/* Claude-inspired search container with white background */}
                    <div className="relative w-full bg-white rounded-2xl py-2 px-4 shadow-md border border-brandMaroon/50 focus-within:ring-2 focus-within:ring-brandMaroon/50">
                        {/* Input area - top row */}
                        <div className="flex items-center mb-2">
                            {/* Input field */}
                            <div className="flex-grow">
                                <textarea
                                    ref={inputRef}
                                    value={query}
                                    onChange={handleTextareaInput}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault();
                                            handleSearch();
                                        }
                                    }}
                                    placeholder={placeholder}
                                    className="w-full resize-none bg-transparent border-none focus:outline-none text-gray-700 placeholder-gray-400 py-2 text-left"
                                    rows={1}
                                    style={{
                                        minHeight: '24px',
                                        overflowY: query.split('\n').length > 1 ? 'auto' : 'hidden'
                                    }}
                                />
                            </div>
                        </div>

                        {/* Divider */}
                        <div className="border-t border-gray-100 my-1"></div>

                        {/* Bottom row with toggles on left, actions on right */}
                        <div className="flex justify-between items-center pt-1">
                            {/* Toggles on the left */}
                            <div className="flex space-x-1.5">
                                {/* Only show these toggles when no files are uploaded */}
                                {!hasUploadedFiles && (
                                    <div className="flex flex-wrap items-center gap-1.5">
                                        {/* Type section with label */}
                                        <div className="flex items-center gap-1.5">
                                            <span className="text-xs text-gray-500 font-medium">Type:</span>
                                            {/* Answer Style Selector Buttons - Filter options based on selected sources */}
                                            {answerStyleOptions
                                                .filter(option => {
                                                    // Always show Assistant mode
                                                    if (option.value === 'research_assistant') return true;
                                                    // Only show Lit Review / Deep Research if a source is selected
                                                    return filters.database?.includes("research papers") || filters.database?.includes("internet");
                                                })
                                                .map((option) => (
                                                    <button
                                                        key={option.value}
                                                        type="button"
                                                        onClick={() => handleAnswerStyleChange(option.value)}
                                                        className={`flex items-center px-3 py-1.5 rounded-full text-xs font-medium transition-colors
                                                    ${filters.answerStyle === option.value
                                                                ? 'bg-brandMaroon text-white hover:bg-brandMaroon/90'
                                                                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                            }`}
                                                        // Disable Lit Review if no source selected, but keep Deep Research clickable for pricing popup
                                                        disabled={
                                                            (option.value === 'literature_review' &&
                                                                !(filters.database?.includes("research papers") || filters.database?.includes("internet")))
                                                        }
                                                        title={
                                                            (option.value === 'literature_review' || option.value === 'deep_research') &&
                                                                !(filters.database?.includes("research papers") || filters.database?.includes("internet"))
                                                                ? "Requires Papers or Internet source"
                                                                : option.proOnly && !isPro
                                                                    ? "Requires Pro subscription"
                                                                    : undefined
                                                        }
                                                    >
                                                        {option.icon}
                                                        {option.label}
                                                        {option.proOnly && !isPro && (
                                                            <span className="ml-1 text-[10px] px-1 py-0.5 bg-amber-500 text-white rounded-full">PRO</span>
                                                        )}
                                                    </button>
                                                ))}
                                        </div>

                                        <div className="h-4 w-px bg-gray-200 mx-1"></div>

                                        {/* Source section with label */}
                                        <div className="flex items-center gap-1.5">
                                            <span className="text-xs text-gray-500 font-medium">Source:</span>
                                            {/* Upload PDF button moved to source section */}
                                            <button
                                                type="button"
                                                onClick={onUploadPDFClick}
                                                className={`flex items-center px-3 py-1.5 rounded-full text-xs font-medium transition-colors
                                                ${hasUploadedFiles
                                                        ? 'bg-brandMaroon text-white ring-1 ring-brandMaroon/30'
                                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-brandMaroon'}`}
                                                title={hasUploadedFiles ? "Files uploaded" : "Upload PDFs"}
                                            >
                                                <Files size={16} className="mr-1" />
                                                {hasUploadedFiles ? "Files uploaded" : "Upload PDF"}
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleSourceFilterChange("research papers")}
                                                className={`flex items-center px-3 py-1.5 rounded-full text-xs font-medium transition-colors
                                                    ${filters.database?.includes("research papers")
                                                        ? 'bg-brandMaroon text-white hover:bg-brandMaroon/90'
                                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                    }`}
                                            >
                                                <svg className="mr-1 h-3 w-3" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                                                        stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                Papers
                                            </button>
                                            {filters.answerStyle !== 'deep_research' && (
                                                <button
                                                    type="button"
                                                    onClick={() => handleSourceFilterChange("internet")}
                                                    className={`flex items-center px-3 py-1.5 rounded-full text-xs font-medium transition-colors
                                                        ${filters.database?.includes("internet")
                                                            ? 'bg-brandMaroon text-white hover:bg-brandMaroon/90'
                                                            : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                        }`}
                                                >
                                                    <SearchIcon className="mr-1 h-3 w-3" />
                                                    Internet
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Show an indicator when files are uploaded - modified to be part of the Source section */}
                                {hasUploadedFiles && (
                                    <div className="flex items-center gap-1.5">
                                        <span className="text-xs text-gray-500 font-medium">Source:</span>
                                        <span className="flex items-center px-3 py-1.5 rounded-full text-xs font-medium bg-brandMaroon text-white">
                                            <Files size={16} className="mr-1" />
                                            Using uploaded files
                                        </span>

                                        {/* Remove the Upload PDF button when files are already uploaded */}
                                        <button
                                            type="button"
                                            onClick={onUploadPDFClick}
                                            className="flex items-center px-3 py-1.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-brandMaroon transition-colors"
                                            title="Change uploaded files"
                                        >
                                            <Files size={16} className="mr-1" />
                                            Change files
                                        </button>
                                    </div>
                                )}
                            </div>

                            {/* Actions on the right */}
                            <div className="flex items-center">
                                {/* More filters button - only show when no files are uploaded */}
                                {!hasUploadedFiles && (
                                    <button
                                        type="button"
                                        onClick={openAllFilters}
                                        className="flex items-center px-3 py-1.5 rounded-full text-xs font-medium bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-brandMaroon transition-colors mr-1"
                                        title="More filters"
                                    >
                                        <Sliders size={16} className="mr-1" />
                                        More Filters
                                    </button>
                                )}

                                {/* Search button */}
                                <button
                                    type="submit"
                                    className="p-2 rounded-full bg-brandMaroon hover:bg-brandMaroon/90 flex items-center justify-center transition-colors"
                                >
                                    <SearchIcon className="h-4 w-4 text-white" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            {/* Settings popover for research papers - now hidden and can be accessed via the filter sidebar */}
            {!hasUploadedFiles && filters.database?.includes("research papers") && (
                <Popover open={isOpen} onOpenChange={setIsOpen}>
                    <PopoverContent className="w-56 p-3 hidden" align="start">
                        <h3 className="text-sm font-medium mb-2">Paper Databases</h3>
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <label className="text-sm">Semantic Scholar</label>
                                <Switch
                                    checked={filters.researchPapersFilter?.includes("semanticscholar") || false}
                                    onCheckedChange={() => handleSubFilterToggle("semanticscholar")}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <label className="text-sm">OpenAlex</label>
                                <Switch
                                    checked={filters.researchPapersFilter?.includes("openalex") || false}
                                    onCheckedChange={() => handleSubFilterToggle("openalex")}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <label className="text-sm">PubMed</label>
                                <Switch
                                    checked={filters.researchPapersFilter?.includes("pubmed") || false}
                                    onCheckedChange={() => handleSubFilterToggle("pubmed")}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <label className="text-sm">arXiv</label>
                                <Switch
                                    checked={filters.researchPapersFilter?.includes("arxiv") || false}
                                    onCheckedChange={() => handleSubFilterToggle("arxiv")}
                                />
                            </div>
                        </div>
                    </PopoverContent>
                </Popover>
            )}

            {/* Full filters sidebar - only available when no files are uploaded */}
            <FilterSidebar
                isOpen={showFullFilters && !hasUploadedFiles}
                filters={filters}
                setFilters={setFilters}
                onClose={closeAllFilters}
                onApply={handleSearch}
            />
        </div>
    );
}

export default AdvancedSearchBar; 