import React, { useState } from 'react';
import { Share2, Copy, CheckCircle, Gift } from 'lucide-react';
import { toast } from 'sonner';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import axios from 'axios';
import { serverUrl } from '@/authService';
import { useAuth } from '@/contexts/AuthContext';

interface ShareButtonProps {
    queryHash: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ queryHash }) => {
    const { isPro } = useAuth();
    const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
    const [shareUrl, setShareUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [copied, setCopied] = useState<boolean>(false);
    const [showSocialPrompt, setShowSocialPrompt] = useState<boolean>(false);

    const createShareLink = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`${serverUrl}/reviews/create-share-link`,
                { query_hash: queryHash },
                { withCredentials: true }
            );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error('Failed to create share link');
            }

            const data = response.data;
            const baseUrl = window.location.origin;
            const shareLink = `${baseUrl}/shared/${data.slug}`;
            setShareUrl(shareLink);
        } catch (error) {
            toast.error('Failed to create share link');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleShareClick = () => {
        setShareDialogOpen(true);
        createShareLink();
        if (!isPro) {
            setShowSocialPrompt(true);
        }
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
        toast.success('Share link copied to clipboard');
    };

    const shareToLinkedIn = () => {
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`;
        window.open(url, '_blank');
        toast.success('Thanks for sharing! Use code SHARE10 for 10% off Pro');
    };

    const shareToTwitter = () => {
        const text = 'Check out my literature review created with AnswerThis!';
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(shareUrl)}`;
        window.open(url, '_blank');
        toast.success('Thanks for sharing! Use code SHARE10 for 10% off Pro');
    };

    return (
        <>
            <Button
                variant="default"
                size="sm"
                onClick={handleShareClick}
                className="bg-brandMaroon hover:bg-[#6a001a] text-white font-medium rounded-md flex items-center gap-2 transition-colors"
            >
                <Share2 className="w-4 h-4" />
                {!isPro && <Gift className="w-4 h-4" />}
                Share
            </Button>

            <Dialog open={shareDialogOpen} onOpenChange={setShareDialogOpen}>
                <DialogContent className="sm:max-w-md font-manrope">
                    <DialogHeader>
                        <DialogTitle className="text-brandMaroon font-semibold text-xl">Share Literature Review</DialogTitle>
                    </DialogHeader>
                    <div className="p-4">
                        <div className="space-y-4">
                            <p className="text-sm text-gray-700">
                                Share this literature review with anyone. They'll be able to view it even if they don't have an account.
                            </p>

                            {isLoading ? (
                                <div className="flex justify-center py-4">
                                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-brandMaroon"></div>
                                </div>
                            ) : (
                                <div className="flex items-center space-x-2">
                                    <Input
                                        value={shareUrl}
                                        readOnly
                                        className="flex-1 border-gray-300 focus:border-brandMaroon focus:ring-brandMaroon bg-gray-50"
                                    />
                                    <Button
                                        variant="default"
                                        size="sm"
                                        onClick={handleCopyClick}
                                        className="bg-brandMaroon hover:bg-[#6a001a] flex items-center gap-2"
                                    >
                                        {copied ? (
                                            <CheckCircle className="w-4 h-4" />
                                        ) : (
                                            <Copy className="w-4 h-4" />
                                        )}
                                        {copied ? 'Copied' : 'Copy'}
                                    </Button>
                                </div>
                            )}

                            {!isPro && showSocialPrompt && (
                                <div className="mt-4 p-4 bg-lightMaroon bg-opacity-60 rounded-lg border border-brandMaroon">
                                    <div className="flex items-center gap-2 mb-2">
                                        <Gift className="w-5 h-5 text-brandMaroon" />
                                        <h4 className="font-semibold text-brandMaroon">Get 10% off AnswerThis Pro!</h4>
                                    </div>
                                    <p className="text-sm text-gray-700 mb-3">
                                        Share your result on social media and use code <span className="font-bold">SHARE10</span> to receive 10% off your Pro subscription.
                                    </p>
                                    <div className="flex gap-2">
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={shareToLinkedIn}
                                            className="text-[#0077b5] border-[#0077b5] hover:bg-[#0077b5]/10"
                                        >
                                            Share on LinkedIn
                                        </Button>
                                        <Button
                                            variant="outline"
                                            size="sm"
                                            onClick={shareToTwitter}
                                            className="text-[#1DA1F2] border-[#1DA1F2] hover:bg-[#1DA1F2]/10"
                                        >
                                            Share on X
                                        </Button>
                                    </div>
                                </div>
                            )}

                            <p className="text-xs text-gray-500">
                                Note: Anyone with this link can view this literature review
                            </p>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ShareButton;