import React, { useState, useEffect, useCallback } from 'react'
import { Editor } from '@tiptap/react'
import { SourceInfo } from '@/authService'
import GlobalCopyCitation, { GlobalCitationStyleControl, useGlobalCitationStyle } from '@/contexts/citationContext';

interface CitationManagerProps {
    editor: Editor
    onAnswerUpdate: (html: string) => void
}

interface UniqueCitation {
    sourceInfo: SourceInfo
    number: number
    positions: number[]
    occurrences: number
}

const CitationManager: React.FC<CitationManagerProps> = ({
    editor,
    onAnswerUpdate,
}) => {
    const [uniqueCitations, setUniqueCitations] = useState<UniqueCitation[]>([])
    const { globalStyle } = useGlobalCitationStyle();

    const extractCitations = useCallback(() => {
        if (!editor) return

        const citationMap = new Map<string, UniqueCitation>()

        editor.state.doc.descendants((node, pos) => {
            if (node.type.name === 'citation' && node.attrs.sourceInfo) {
                const sourceId = node.attrs.sourceInfo.id
                if (citationMap.has(sourceId)) {
                    const citation = citationMap.get(sourceId)!
                    citation.positions.push(pos)
                    citation.occurrences++
                } else {
                    citationMap.set(sourceId, {
                        sourceInfo: node.attrs.sourceInfo,
                        number: node.attrs.citationNumber,
                        positions: [pos],
                        occurrences: 1,
                    })
                }
            }
        })

        setUniqueCitations(
            Array.from(citationMap.values()).sort((a, b) => a.number - b.number)
        )
    }, [editor])

    useEffect(() => {
        if (!editor) return

        extractCitations()

        const updateHandler = () => {
            extractCitations()
        }

        editor.on('update', updateHandler)
        editor.on('selectionUpdate', updateHandler)

        return () => {
            editor.off('update', updateHandler)
            editor.off('selectionUpdate', updateHandler)
        }
    }, [editor, extractCitations])

    useEffect(() => {
        if (!editor) return

        // Update all citations with the currently selected style.
        editor.chain()
            .focus()
            .updateCitationStyles(globalStyle)
            .run()
        editor.chain().focus().updateCitationNumbers().run()

        onAnswerUpdate(editor.getHTML())
    }, [globalStyle, editor, onAnswerUpdate])

    // Delete citation nodes and update numbering in a single transaction.
    const handleDelete = (positions: number[]) => {
        editor
            .chain()
            .focus()
            .command(({ tr, dispatch }) => {
                // Delete citation nodes from highest to lowest positions.
                positions.sort((a, b) => b - a).forEach((pos) => {
                    tr.delete(pos, pos + 1)
                })

                // Recalculate citation numbering on the updated document.
                const sourceMap = new Map<string, number>()
                let counter = 1
                tr.doc.descendants((node) => {
                    if (node.type.name === 'citation') {
                        const sourceId = node.attrs.sourceInfo?.id
                        if (sourceId && !sourceMap.has(sourceId)) {
                            sourceMap.set(sourceId, counter++)
                        }
                    }
                })

                tr.doc.descendants((node, pos) => {
                    if (node.type.name === 'citation') {
                        const sourceId = node.attrs.sourceInfo?.id
                        if (sourceId) {
                            const number = sourceMap.get(sourceId) || 1
                            tr.setNodeMarkup(pos, undefined, {
                                ...node.attrs,
                                citationNumber: number,
                            })
                        }
                    }
                })

                if (dispatch) dispatch(tr)
                return true
            })
            .run()

        onAnswerUpdate(editor.getHTML())
        extractCitations()
    }

    const handleMove = (fromIndex: number, toIndex: number) => {
        if (!editor || fromIndex === toIndex) return

        const citations = uniqueCitations
        if (
            fromIndex < 0 ||
            toIndex < 0 ||
            fromIndex >= citations.length ||
            toIndex >= citations.length
        ) {
            return
        }

        // Create a map of current source IDs to their desired new numbers.
        const numberMap = new Map<string, number>()
        const reorderedCitations = [...citations]
        const [movedCitation] = reorderedCitations.splice(fromIndex, 1)
        reorderedCitations.splice(toIndex, 0, movedCitation)

        reorderedCitations.forEach((citation, index) => {
            numberMap.set(citation.sourceInfo.id, index + 1)
        })

        // Update all citations in the document.
        const tr = editor.state.tr
        editor.state.doc.descendants((node, pos) => {
            if (node.type.name === 'citation') {
                const sourceId = node.attrs.sourceInfo?.id
                if (sourceId && numberMap.has(sourceId)) {
                    tr.setNodeMarkup(pos, undefined, {
                        ...node.attrs,
                        citationNumber: numberMap.get(sourceId),
                    })
                }
            }
        })

        editor.view.dispatch(tr)
        onAnswerUpdate(editor.getHTML())
        extractCitations()
    }

    return (

        <div className="citation-manager border rounded-lg p-4 mt-4">
            <div className="citation-component p-4 bg-gray-100 rounded-lg shadow-md">
                <div className="flex items-center justify-between mb-4 bg-white p-3 rounded-md shadow-sm">
                    <h3 className="text-lg font-semibold">Change Citation Style</h3>
                    <GlobalCitationStyleControl />
                </div>

                <div className="space-y-2">
                    {uniqueCitations.length > 0 ? (
                        uniqueCitations.map((citation, index) => (
                            <div
                                key={citation.sourceInfo.id}
                                className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded hover:bg-gray-100"
                            >
                                <GlobalCopyCitation source={citation.sourceInfo} prefix={`[${index + 1}] `} />

                                <div className="flex items-center space-x-2">
                                    <button
                                        onClick={() => handleMove(index, index - 1)}
                                        disabled={index === 0}
                                        className="p-1 rounded hover:bg-gray-200 disabled:opacity-50"
                                        title="Move up"
                                    >
                                        ↑
                                    </button>
                                    <button
                                        onClick={() => handleMove(index, index + 1)}
                                        disabled={index === uniqueCitations.length - 1}
                                        className="p-1 rounded hover:bg-gray-200 disabled:opacity-50"
                                        title="Move down"
                                    >
                                        ↓
                                    </button>
                                    <button
                                        onClick={() => handleDelete(citation.positions)}
                                        className="p-1 text-red-600 rounded hover:bg-red-100"
                                        title="Delete all occurrences"
                                    >
                                        ×
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-gray-500 text-center py-4">
                            No citations added yet
                        </div>
                    )}
                </div>        </div>


        </div>
    )
}

export default CitationManager
