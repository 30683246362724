import { toast } from "sonner";
import { useState, useRef, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Upload, Trash2, File } from "lucide-react";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card, CardContent } from "@/components/ui/card";

interface FileUploadBarProps {
  onFilesChange: (files: File[]) => void;
  files?: File[];
  show: boolean;
  reverseShow: () => void;
}

function FileUploadBar({ onFilesChange, show, reverseShow, files = [] }: FileUploadBarProps) {
  const { isPro } = useAuth();
  const MAX_FILE_SIZE = isPro ? 120 * 1024 * 1024 : 15 * 1024 * 1024;
  const maxFileSize = isPro ? "100MB" : "15MB";

  const [dragCounter, setDragCounter] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(files);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Sync the internal state with the files prop when it changes
  useEffect(() => {
    setUploadedFiles(files);
  }, [files]);

  const updateFiles = (newFiles: File[]) => {
    setUploadedFiles(newFiles);
    onFilesChange(newFiles);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => prev + 1);
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter((prev) => prev - 1);
  };

  const onDropHandler = (
    e: React.DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    setDragCounter(0);

    let files: File[] = [];

    if ("dataTransfer" in e) {
      files = Array.from(e.dataTransfer.files);
    } else {
      if (e.currentTarget.files) {
        files = Array.from(e.currentTarget.files);
      }
    }

    //Filter files based on type of file
    const validFileTypes = [
      "application/pdf"
    ];

    const typeValidFiles = files.filter((file) =>
      validFileTypes.includes(file.type)
    );

    if (typeValidFiles.length === 0) {
      toast.error("Please upload PDF files only.")
      return;
    }

    //Filter files based on individual file size limit
    const sizeFilteredFiles = typeValidFiles.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        toast.error(`File ${file.name} is too large. Maximum size is ${maxFileSize}`)
        return false;
      }
      return true;
    });

    // Check if the total size of selected files exceeds the limit
    const totalSize = sizeFilteredFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    if (totalSize > MAX_FILE_SIZE) {
      toast.error(`The total size of files exceeds the maximum limit. ${maxFileSize}`);
      return;
    }

    updateFiles([...uploadedFiles, ...sizeFilteredFiles]);
    console.log("Valid files:", sizeFilteredFiles);
  };

  function formatBytesToKB(bytes: number) {
    return (bytes / 1024).toFixed(2) + " kB";
  }

  const handleDeleteFile = (fileIndex: number) => {
    const newFiles = uploadedFiles.filter((_, index) => index !== fileIndex);
    updateFiles(newFiles);
  };

  return (
    <Sheet open={show} onOpenChange={reverseShow}>
      <SheetContent className="w-full sm:max-w-md overflow-y-auto">
        <SheetHeader className="mb-5">
          <SheetTitle>Upload Files</SheetTitle>

        </SheetHeader>

        <div className="flex flex-col gap-6">
          {/* Drop Zone */}
          <div
            className={`border-2 border-dashed ${dragCounter > 0 ? "border-primary bg-primary/5" : "border-gray-300"
              } rounded-lg p-6 transition-colors duration-200 flex flex-col items-center justify-center text-center`}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDropHandler}
          >
            <Upload className="h-12 w-12 text-gray-400 mb-4" />
            <p className="text-sm font-medium text-gray-700 mb-1">Drag and drop your files here</p>
            <p className="text-xs text-gray-500 mb-3">OR</p>
            <Button
              variant="outline"
              onClick={() => fileInputRef.current?.click()}
              className="mt-2"
            >
              Browse files
            </Button>
            <input
              ref={fileInputRef}
              id="fileInput"
              type="file"
              multiple
              className="hidden"
              accept=".pdf"
              onChange={onDropHandler}
            />
            <p className="text-xs text-gray-500 mt-4">Maximum file size: {maxFileSize}</p>
            {!isPro && (
              <p className="text-xs text-gray-500">Upgrade to Pro to upload files up to 100MB</p>
            )}
          </div>

          {/* File List */}
          {uploadedFiles.length > 0 && (
            <Card>
              <CardContent className="p-0">
                <ScrollArea className="h-[40vh]">
                  <div className="p-1">
                    {uploadedFiles.map((file, index) => (
                      <div
                        key={file.name + index}
                        className="flex items-center justify-between p-3 border-b border-gray-100 last:border-0 hover:bg-gray-50 rounded-md"
                      >
                        <div className="flex items-center gap-3">
                          <File className="h-6 w-6 text-primary/70" />
                          <div className="flex flex-col">
                            <span className="text-sm font-medium truncate max-w-[180px]">
                              {file.name}
                            </span>
                            <span className="text-xs text-gray-500">
                              {formatBytesToKB(file.size)}
                            </span>
                          </div>
                        </div>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDeleteFile(index)}
                          className="h-8 w-8 text-red-500 hover:text-red-700 hover:bg-red-50"
                        >
                          <Trash2 className="h-4 w-4" />
                          <span className="sr-only">Delete</span>
                        </Button>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </CardContent>
            </Card>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default FileUploadBar;
