import React from 'react';

interface SimpleLoadingProps {
    message?: string;
    subMessage?: string;
}

const SimpleLoading: React.FC<SimpleLoadingProps> = ({
    message = "Loading your results",
    subMessage = "This will only take a moment..."
}) => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen-header text-darkMaroon">
            <div className="w-24 h-24 mb-4">
                <div className="animate-spin rounded-full h-24 w-24 border-b-2 border-brandMaroon"></div>
            </div>
            <h2 className="text-xl font-semibold mb-2 text-brandMaroon">{message}</h2>
            <p className="text-sm text-gray-600">{subMessage}</p>
        </div>
    );
};

export default SimpleLoading; 