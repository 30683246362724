import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/HomePage/Homepage.tsx";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import AnswerThisAnswer from "./pages/Answer/AnswerThisAnswer.tsx";
import AnswerThisSummary from "./pages/Summary/AnswerThisSummary";
import ForgotPassword from "./pages/Auth/ForgotPassword.tsx";
import ResetPassword from "./pages/Auth/ResetPassword";
import Terms from "./pages/Terms/Terms";
import { PlasmicRootProvider } from '@plasmicapp/loader-react';
import ProtectedRoute from "./components/routes/ProtectedRoute";
import UniversityLanding from "./pages/UniversityLanding/UniversityLanding.tsx";
import UnProtectedRoute from "./components/routes/UnProtectedRoute";
import AnswerThisChat from "./pages/Chat/AnswerThisChat";
import Layout from "./Layout.tsx";
import AffiliatePage from "./pages/Affiliate/AffiliateLandingPage.tsx";
import Blogs from "./pages/Blog/Blogs.tsx";
import NewPricing from "./pages/Pricing/NewPricing.tsx";
import Contact from "./pages/Contact/Contact.tsx";
import HeaderLayout from "./HeaderLayout.tsx";
import PricingRedirect from "./pages/Pricing/PricingRedirect.tsx";
import Profile from "./pages/Profile.tsx";
import TabSearch from "./pages/MainUploadPage/TabSearch.tsx";
import { SidebarProvider } from "./contexts/sidebarContext.tsx";
import { QueryProvider } from "./contexts/queryContext.tsx";
import Projects from "./pages/Projects/Projects.tsx";
import { useEffect } from "react";
import { AuthProvider } from "./contexts/AuthContext.tsx";
import { ProcessingProvider } from './contexts/processingContext';
import FullScreenPaperInfo from "./pages/FullScreenPaperView.tsx";
import ClarityScript from "./ClarityScript.tsx";
import SuccessPage from "./pages/Pricing/PaymentSuccess.tsx";
import CancelPage from "./pages/Pricing/PaymentCancel.tsx";
import { CitationStyleProvider } from "./contexts/citationContext.tsx";
import FirstTimeSearch from "./pages/Auth/FirstTime.tsx";
import { PLASMIC } from "./components/plasmicLoader.tsx";
import { CatchAllPage } from "./pages/PlasmicRelated/CatchAll.tsx";
import { PlasmicCanvasHost } from '@plasmicapp/loader-react';
import { LibraryProvider } from "./contexts/LibraryContext";
import EditorPage from "./components/editor/EditorPage.tsx";
import GuidesComponent from "./components/sidebar/GuidesComponent";
import PricingPopupContainer from "./components/pricing/PricingPopupContainer";
import AdminPanel from "./pages/Admin/AdminPanel.tsx";
import TagManager from "react-gtm-module";
import { PostHogProvider } from 'posthog-js/react'
import SharedLiteratureReview from "./pages/SharedLiteratureReview.tsx";
const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
}
const App: React.FC = () => {
  useEffect(() => {
    const hostname = window.location.hostname;
    const isAnswerThisDomain = hostname.startsWith('answerthis.io');
    const isHttps = window.location.protocol === 'https:';

    if (isAnswerThisDomain && !isHttps) {
      window.location.href = window.location.href.replace('http:', 'https:');
    }

    const tagManagerArgs = {
      gtmId: 'GTM-TFBGBT45'
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <AuthProvider>
        <BrowserRouter>
          <ClarityScript />
          <Routes>
            {/* Routes that need all providers */}
            <Route path="admin" element={
              <ProcessingProvider>
                <SidebarProvider>
                  <QueryProvider>
                    <CitationStyleProvider>
                      <LibraryProvider>
                        <PlasmicRootProvider loader={PLASMIC}>
                          <PricingPopupContainer />
                          <ProtectedRoute>
                            <AdminPanel />
                          </ProtectedRoute>
                        </PlasmicRootProvider>
                      </LibraryProvider>
                    </CitationStyleProvider>
                  </QueryProvider>
                </SidebarProvider>
              </ProcessingProvider>
            } />

            {/* All complex, protected routes that need all providers */}
            <Route element={
              <ProcessingProvider>
                <SidebarProvider>
                  <QueryProvider>
                    <CitationStyleProvider>
                      <LibraryProvider>
                        <PricingPopupContainer />
                        <Layout />
                      </LibraryProvider>
                    </CitationStyleProvider>
                  </QueryProvider>
                </SidebarProvider>
              </ProcessingProvider>
            }>
              <Route path=":id/ask-answerthis" element={<ProtectedRoute><TabSearch /></ProtectedRoute>} />
              <Route path=":id/onboarding" element={<ProtectedRoute><FirstTimeSearch /></ProtectedRoute>} />
              <Route path="/payment" element={<PricingRedirect />} />
              <Route path="/projects" element={<Projects />} />
              <Route path=":id/doc/:docId" element={<ProtectedRoute><EditorPage /></ProtectedRoute>} />
              <Route path=":id/pricing" element={<ProtectedRoute><NewPricing addInMobileMargin={true} /></ProtectedRoute>} />
              <Route path=":id/contact-us" element={<ProtectedRoute><Contact /></ProtectedRoute>} />
              <Route path=":id/affiliate" element={<ProtectedRoute><AffiliatePage noPaddingTop={true} /></ProtectedRoute>} />
              <Route path=":id/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
              <Route path=":id/paper/:paperId" element={<FullScreenPaperInfo />} />
              <Route path=":id/answerthis/answer/chat/:chatIndex" element={<ProtectedRoute><AnswerThisChat /></ProtectedRoute>} />
              <Route path=":id/answerthis/answer/summary/:query_hash" element={<ProtectedRoute><AnswerThisSummary /></ProtectedRoute>} />
              <Route path=":id/answerthis/answer/:query_hash" element={<ProtectedRoute><AnswerThisAnswer /></ProtectedRoute>} />
              <Route path=":id/guides" element={<ProtectedRoute><GuidesComponent /></ProtectedRoute>} />
            </Route>

            {/* Simple routes that don't need all the providers */}
            <Route path="/">
              {/* Auth routes - no need for PlasmicRootProvider */}
              <Route path="login" element={
                <UnProtectedRoute><Login /></UnProtectedRoute>
              } />
              <Route path="signup" element={
                <UnProtectedRoute><Signup /></UnProtectedRoute>
              } />

              {/* Public routes with HeaderLayout - no need for PlasmicRootProvider */}
              <Route element={<HeaderLayout />}>
                <Route index element={<UnProtectedRoute><Homepage /></UnProtectedRoute>} />
                <Route path="blogs" element={<Blogs />} />
                <Route path="contact-us" element={<Contact />} />
                <Route path="terms" element={<Terms />} />
                <Route path="pricing" element={<NewPricing />} />
                <Route path="university" element={<UniversityLanding />} />
                <Route path="affiliate" element={<AffiliatePage />} />
                <Route path="checkout/success" element={<SuccessPage />} />
                <Route path="checkout/cancel" element={<CancelPage />} />
                <Route path="forgotPassword" element={<ForgotPassword />} />
                <Route path="reset_password/:token" element={<ResetPassword />} />
                <Route path="shared/:slug" element={<SharedLiteratureReview />} />

              </Route>

              {/* Plasmic hosting route - needs PlasmicRootProvider */}
              <Route path="plasmic-host" element={
                <PlasmicRootProvider loader={PLASMIC}>
                  <PlasmicCanvasHost />
                </PlasmicRootProvider>
              } />

              {/* Catch-all route - needs PlasmicRootProvider */}
              <Route path="*" element={
                <PlasmicRootProvider loader={PLASMIC}>
                  <CatchAllPage />
                </PlasmicRootProvider>
              } />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </PostHogProvider>
  );
};

export default App;