import React from 'react';
import { X } from 'lucide-react';

interface UploadedFilesListProps {
    files: File[];
    onRemoveFile: (index: number) => void;
}

const UploadedFilesList: React.FC<UploadedFilesListProps> = ({ files, onRemoveFile }) => {
    if (files.length === 0) return null;

    return (
        <div className="mt-2 mb-2">
            <h3 className="text-sm font-semibold text-gray-700 mb-2">Uploaded Files:</h3>
            <div className="flex flex-wrap gap-2">
                {files.map((file, index) => (
                    <div key={file.name + index} className="flex items-center bg-gray-100 rounded-full pl-3 pr-2 py-1">
                        <span className="text-xs font-medium mr-2">{file.name}</span>
                        <button
                            onClick={() => onRemoveFile(index)}
                            className="text-gray-500 hover:text-red-500 focus:outline-none"
                            aria-label={`Remove ${file.name}`}
                        >
                            <X size={14} />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UploadedFilesList;