import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paperclip, Search, Plus, Clock, Grid, List, MoreVertical, Trash2, HelpCircle } from 'lucide-react';
import { WriterDocument } from './types';
import { CreateDocumentModal } from './CreateDocumentModal';
import { getDocumentList, createDocument } from './documentService';
import { toast } from 'sonner';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { formatDistanceToNow } from 'date-fns';
import axios from 'axios';
import { serverUrl } from '../../authService';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

// Add this function to show the demo iframe
export const showDemoIframe = (src: string) => {
    // Create a div to hold the iframe
    const embedDiv = document.createElement('div');
    embedDiv.style.position = 'fixed';
    embedDiv.style.top = '0';
    embedDiv.style.left = '0';
    embedDiv.style.width = '100%';
    embedDiv.style.height = '100%';
    embedDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.75)';
    embedDiv.style.zIndex = '9999';
    embedDiv.style.display = 'flex';
    embedDiv.style.justifyContent = 'center';
    embedDiv.style.alignItems = 'center';

    // Create the content container with padding
    const contentDiv = document.createElement('div');
    contentDiv.style.position = 'relative';
    contentDiv.style.boxSizing = 'content-box';
    contentDiv.style.maxHeight = '80vh';
    contentDiv.style.maxHeight = '80svh';
    contentDiv.style.width = '80%';
    contentDiv.style.aspectRatio = '1.764294049008168';
    contentDiv.style.padding = '40px 0 40px 0';

    // Create the iframe
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.loading = 'lazy';
    iframe.title = 'AnswerThis Editor Demo';
    iframe.allow = 'clipboard-write';
    iframe.frameBorder = '0';
    iframe.style.position = 'absolute';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.setAttribute('webkitallowfullscreen', 'true');
    iframe.setAttribute('mozallowfullscreen', 'true');
    iframe.setAttribute('allowfullscreen', '');

    // Add close button
    const closeButton = document.createElement('button');
    closeButton.innerHTML = '&times;';
    closeButton.style.position = 'absolute';
    closeButton.style.top = '10px';
    closeButton.style.right = '10px';
    closeButton.style.backgroundColor = 'white';
    closeButton.style.border = 'none';
    closeButton.style.borderRadius = '50%';
    closeButton.style.width = '30px';
    closeButton.style.height = '30px';
    closeButton.style.fontSize = '20px';
    closeButton.style.cursor = 'pointer';
    closeButton.style.zIndex = '10000';
    closeButton.onclick = () => document.body.removeChild(embedDiv);

    // Assemble the elements
    contentDiv.appendChild(iframe);
    embedDiv.appendChild(contentDiv);
    embedDiv.appendChild(closeButton);

    // Add to body
    document.body.appendChild(embedDiv);
};

interface DocumentHistoryProps {
    projectId?: string;
}

const DocumentCard = ({ doc, onDocumentClick, onDeleteDocument }: {
    doc: WriterDocument;
    onDocumentClick: (doc: WriterDocument) => void;
    onDeleteDocument: (id: string) => void;
}) => {
    return (
        <Card className="cursor-pointer hover:shadow-md transition-shadow border border-gray-200 group relative bg-white flex flex-col h-64">
            <div className="absolute inset-0" onClick={() => onDocumentClick(doc)}></div>
            <CardHeader className="pb-2 pt-6 flex-none">
                <CardTitle className="line-clamp-1 text-lg font-medium">{doc.title}</CardTitle>
                <CardDescription className="text-xs flex items-center mt-1">
                    <Clock size={12} className="mr-1 flex-shrink-0" />
                    <span>{formatDistanceToNow(new Date(doc.updated_at || doc.created_at), { addSuffix: true })}</span>
                </CardDescription>
            </CardHeader>
            <CardContent className="pb-2 flex-grow">
                <p className="text-sm text-gray-600 line-clamp-3">{doc.description || "No description"}</p>
            </CardContent>
            <CardFooter className="pt-4 pb-4 border-t flex justify-between items-center mt-auto flex-none">
                <DocumentMetadata doc={doc} />
                <DocumentActions doc={doc} onDelete={onDeleteDocument} />
            </CardFooter>
        </Card>
    );
};

const DocumentListItem = ({ doc, onDocumentClick, onDeleteDocument }: {
    doc: WriterDocument;
    onDocumentClick: (doc: WriterDocument) => void;
    onDeleteDocument: (id: string) => void;
}) => {
    return (
        <div
            className="flex items-center justify-between p-5 hover:bg-gray-50 cursor-pointer transition-colors border-b border-gray-200 last:border-b-0"
            onClick={() => onDocumentClick(doc)}
        >
            <div className="flex-1 min-w-0 pr-4">
                <h3 className="text-base font-medium text-gray-900 mb-1">{doc.title}</h3>
                <p className="text-sm text-gray-500 line-clamp-1">{doc.description || "No description"}</p>
            </div>
            <div className="flex items-center gap-6">
                <div className="text-xs text-gray-500 whitespace-nowrap flex items-center">
                    <Clock size={12} className="mr-1 hidden sm:block" />
                    <span>{formatDistanceToNow(new Date(doc.updated_at || doc.created_at), { addSuffix: true })}</span>
                </div>
                <DocumentMetadata doc={doc} className="hidden md:flex" />
                <DocumentActions doc={doc} onDelete={onDeleteDocument} />
            </div>
        </div>
    );
};

const DocumentMetadata = ({ doc, className = "" }: { doc: WriterDocument; className?: string }) => (
    <div className={`flex items-center text-xs text-gray-500 ${className}`}>
        <Paperclip size={12} className="mr-1 flex-shrink-0" />
        <span>{doc.is_public ? "Public" : "Private"}</span>
    </div>
);

const DocumentActions = ({ doc, onDelete }: { doc: WriterDocument; onDelete: (id: string) => void }) => (
    <DropdownMenu>
        <DropdownMenuTrigger asChild>
            <Button
                variant="ghost"
                size="sm"
                className="h-8 w-8 p-0 relative z-10 rounded-full"
                onClick={(e) => e.stopPropagation()}
            >
                <MoreVertical size={16} />
            </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
            <DropdownMenuItem
                className="text-red-600 cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation();
                    onDelete(doc.id);
                }}
            >
                <Trash2 size={14} className="mr-2" />
                Delete
            </DropdownMenuItem>
        </DropdownMenuContent>
    </DropdownMenu>
);

const DocumentHistory: React.FC<DocumentHistoryProps> = () => {
    const { id: paramProjectId } = useParams<{ id: string }>();
    const activeProjectId = paramProjectId;

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [documents, setDocuments] = useState<WriterDocument[]>([]);
    const [loading, setLoading] = useState(true);
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const navigate = useNavigate();

    const fetchDocuments = useCallback(async (projectId: string) => {
        try {
            setLoading(true);
            const docsResponse = await getDocumentList(projectId);

            if (docsResponse.length === 0) {
                const defaultDoc = await createDocument(
                    "Untitled Document",
                    "New document",
                    projectId
                );
                setDocuments([defaultDoc]);
                navigate(`/${projectId}/doc/${defaultDoc.id}`);
            } else {
                const sortedDocs = [...docsResponse].sort(
                    (a, b) => new Date(b.updated_at || b.created_at).getTime() - new Date(a.updated_at || a.created_at).getTime()
                );
                setDocuments(sortedDocs);
            }
        } catch (error) {
            toast.error('Failed to load documents');
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        if (activeProjectId) fetchDocuments(activeProjectId);
    }, [activeProjectId, fetchDocuments]);

    const handleDocumentCreated = (newDocument: WriterDocument) => {
        navigate(`/${activeProjectId}/doc/${newDocument.id}`);
    };

    const handleDocumentClick = (doc: WriterDocument) => {
        navigate(`/${activeProjectId}/doc/${doc.id}`);
    };

    const handleDeleteDocument = async (docId: string) => {
        try {
            await axios.post(
                `${serverUrl}/writer/delete-document`,
                { document_id: docId },
                { withCredentials: true }
            );
            toast.success('Document deleted');
            setDocuments(documents.filter(doc => doc.id !== docId));
        } catch (error) {
            toast.error('Failed to delete document');
        }
    };

    const filteredDocuments = documents.filter(doc =>
        doc.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (doc.description && doc.description.toLowerCase().includes(searchQuery.toLowerCase()))
    );

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-brandMaroon"></div>
            </div>
        );
    }

    return (
        <div className="container mx-auto py-2 px-4 max-w-7xl h-full overflow-y-auto">
            <div className="flex flex-col gap-8">
                {/* Header Section */}
                <div className="bg-white rounded-lg p-2">
                    <div className="flex flex-col gap-6">
                        <div className="flex justify-between items-center">
                            <div className="space-y-1">
                                <h1 className="text-2xl font-semibold text-gray-900">Write your documents with the assistance of AI</h1>
                            </div>

                            <button
                                data-supademo-demo="cm7mbj5en1w0cddumsngnkr1a"
                                className="text-gray-500 hover:text-brandMaroon h-9 w-9 flex items-center justify-center"
                                onClick={() => showDemoIframe('https://demo.answerthis.io/embed/cm7mbj5en1w0cddumsngnkr1a?embed_v=2')}
                            >
                                <HelpCircle size={20} />
                            </button>

                        </div>

                        <div className="flex flex-col sm:flex-row gap-4">
                            <div className="relative flex-1">
                                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
                                <Input
                                    type="text"
                                    placeholder="Search documents..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="pl-10 w-full h-10"
                                />
                            </div>

                            <div className="flex gap-2">
                                <div className="flex items-center border rounded-md overflow-hidden">
                                    <Button
                                        variant={viewMode === 'grid' ? "default" : "ghost"}
                                        size="sm"
                                        onClick={() => setViewMode('grid')}
                                        className="rounded-none"
                                    >
                                        <Grid size={16} />
                                    </Button>
                                    <Button
                                        variant={viewMode === 'list' ? "default" : "ghost"}
                                        size="sm"
                                        onClick={() => setViewMode('list')}
                                        className="rounded-none"
                                    >
                                        <List size={16} />
                                    </Button>
                                </div>

                                <Button
                                    onClick={() => setIsCreateModalOpen(true)}
                                    className="flex items-center gap-2 bg-brandMaroon hover:bg-brandMaroon/90 h-10 whitespace-nowrap"
                                >
                                    <Plus size={16} />
                                    <span>New Document</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Content Section */}
                {filteredDocuments.length === 0 ? (
                    <div className="text-center py-16 px-2 bg-white rounded-lg ">
                        <Paperclip className="mx-auto h-12 w-12 text-gray-400" />
                        <h3 className="mt-4 text-lg font-medium text-gray-900">No documents found</h3>
                        <p className="mt-2 text-sm text-gray-500 max-w-md mx-auto">
                            {searchQuery ? "Try a different search term" : "Create a new document to get started"}
                        </p>
                        <div className="mt-6">
                            <Button
                                onClick={() => setIsCreateModalOpen(true)}
                                className="bg-brandMaroon hover:bg-brandMaroon/90"
                            >
                                Create Document
                            </Button>
                        </div>
                    </div>
                ) : viewMode === 'grid' ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        {filteredDocuments.map((doc) => (
                            <DocumentCard
                                key={doc.id}
                                doc={doc}
                                onDocumentClick={handleDocumentClick}
                                onDeleteDocument={handleDeleteDocument}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="bg-white rounded-lg  overflow-hidden">
                        {filteredDocuments.map((doc) => (
                            <DocumentListItem
                                key={doc.id}
                                doc={doc}
                                onDocumentClick={handleDocumentClick}
                                onDeleteDocument={handleDeleteDocument}
                            />
                        ))}
                    </div>
                )}
            </div>

            <CreateDocumentModal
                isOpen={isCreateModalOpen}
                onClose={() => setIsCreateModalOpen(false)}
                projectId={activeProjectId!}
                onDocumentCreated={handleDocumentCreated}
            />
        </div>
    );
};

export default DocumentHistory;