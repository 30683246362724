import "./AnswerThisAnswer.css";
import Loading from "../../components/Loading";
import SimpleLoading from "../../components/SimpleLoading";
import "../../components/Sidebar.css"
import { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useLocation, } from "react-router-dom";
import {
  rerunQuery,
  Filters,
  defaultFilters,
  SourceInfo,
  getQueryStatus,
  storeQueryStatus,
  QueryStatus
} from "../../authService";
import AnswerTable from "../../components/AnswerTable/AnswerTable";
// import Feedback from "../../components/Feedback";
import { fetchReview, FetchReviewResult } from "../../HelperUtils/AnswerFetcher";
import { toast, Toaster } from "sonner";
import SeoComp from "../../components/SeoComp";
import { useSidebar } from "../../contexts/sidebarContext";
import { useAuth } from "@/contexts/AuthContext";
import { AskNewQuestion } from "./AskNewQuestion";
import SplitView from "../../components/SplitView";
import AnswerView from "../../components/AnswerView";

import ExpandableSection from "@/components/expandableSections/ExpandableSection";
import { AddToEditorButton } from "@/components/editor/addToEditor";
import ShareButton from "@/components/ShareButton";

const AnswerThisAnswer: React.FC = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const { query } = location.state || {};
  const { checkCredits, removeCredits } = useAuth();

  const [unusedSources, setUnusedSources] = useState<SourceInfo[]>([]);
  const { refreshSidebar } = useSidebar();
  const citationStyle = 'numberedLinks';
  const { query_hash } = useParams<{ query_hash: string }>();
  const [reviewData, setReviewData] = useState<FetchReviewResult | null>(null);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(0);
  const [sentRerun, setSetRerun] = useState<boolean>(false);
  const [answerFilters, setAnswerFilters] = useState<Filters>(defaultFilters);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const askNewQuestionRef = useRef<HTMLDivElement>(null);
  const [pendingCitationId, setPendingCitationId] = useState<string | null>(null);
  const [queryStatus, setQueryStatus] = useState<QueryStatus | null>(null);

  // Check for query status on component mount and URL changes
  useEffect(() => {
    if (query_hash) {
      const status = getQueryStatus(query_hash);
      setQueryStatus(status);

      // If no status found, create a new one marked as "loading existing result"
      if (!status && query_hash) {
        storeQueryStatus(query_hash, "research_assistant", false);
        setQueryStatus({
          hash: query_hash,
          isNew: false,
          type: "research_assistant",
          timestamp: Date.now(),
          seenBefore: false
        });
      }
    }
  }, [query_hash]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNewQuestionSubmit = async (query: string) => {
    if (!checkCredits(id!, 1)) {
      return;
    }

    try {
      setIsLoading(true);
      await rerunQuery(query_hash!, query);
      await removeCredits(1);
      refreshSidebar();
      setSetRerun(!sentRerun);
    } catch (error) {
      console.error("Error rerunning query:", error);
      toast.error("An error occurred while processing your question");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetchReview(query_hash!);
        console.log("Fetched data:", data);
        // Check if there are no results and reload the page if needed
        if (!data || !data.answers || data.answers.length === 0) {
          console.log("No results found, refreshing page...");
          window.location.reload();
          return;
        }

        setReviewData(data);
        setCurrentAnswerIndex(data.answers.length - 1);

        if (data.answers.length > 0) {
          const answerStyle = data.answers[0].answerObject.filters?.answerStyle;
          setAnswerFilters(data.answers[0].answerObject.filters);

          // Update query status after successful data fetch
          if (query_hash && answerStyle) {
            storeQueryStatus(query_hash, answerStyle, false);
            setQueryStatus(prevStatus => ({
              ...prevStatus!,
              isNew: false,
              type: answerStyle || "research_assistant",
              seenBefore: true
            }));
          }

          if (data.answers[0].answerObject.unused_sources && data.answers[0].answerObject.unused_sources.result) {
            setUnusedSources(Object.values(data.answers[0].answerObject.unused_sources.result));
          } else {
            setUnusedSources([]);
          }
        }
        refreshSidebar();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };

    if (query_hash) {
      fetchData();
    }
  }, [query_hash, citationStyle, sentRerun]);

  const handleCitationClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    const isLink = target.tagName === 'A' && target.getAttribute('href')?.startsWith('#');

    if (isLink) {
      e.preventDefault();
      if (!isMobile) {
        const id = target.getAttribute('href')!.substring(1);
        handleViewTable(id);
      }
    }
  };

  const handleViewTable = (id: string) => {
    document.querySelectorAll('.selected').forEach((el) => {
      el.classList.remove('selected');
    });

    const targetElement = document.getElementById(id);
    if (targetElement) {
      const parentSection = targetElement.closest('.paper-section');
      if (parentSection) {
        parentSection.scrollIntoView({ behavior: 'smooth' });
        targetElement.classList.add('selected');
      } else {
        // Fallback to original behavior if parent section not found
        targetElement.scrollIntoView({ behavior: 'smooth' });
        targetElement.classList.add('selected');
      }
    } else {
      // Element not found - may be on another page
      // Store the ID and communicate with PaperList to find and navigate to it
      setPendingCitationId(id);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleCitationClick);
    return () => {
      document.removeEventListener('click', handleCitationClick);
    };
  }, []);

  const handleAnswerUpdate = useCallback((updatedAnswer: string) => {
    if (reviewData) {
      const updatedAnswers = [...reviewData.answers];
      updatedAnswers[currentAnswerIndex] = {
        ...updatedAnswers[currentAnswerIndex],
        formattedAnswer: updatedAnswer,
      };
      setReviewData({ ...reviewData, answers: updatedAnswers });
    }
  }, [reviewData, currentAnswerIndex]);

  const renderContent = () => {
    const currentAnswerData = reviewData?.answers[currentAnswerIndex];
    const hasSources = currentAnswerData && currentAnswerData.papersArraySorted.length > 0;

    return (
      <div className="h-screen-header"> {/* Manage height, remove pb-24 here if splitview handles it */}
        {hasSources && currentAnswerData ? (
          // Case 1: Render SplitView when sources exist
          <SplitView
            leftTitle="Answer"
            minimizeForMobile={true}
            minimizeForDesktop={true}
            leftComponent={
              // Answer Panel for SplitView - needs internal scroll
              <div className="bg-white rounded-lg h-full overflow-auto">
                {reviewData && reviewData.answers.map((answer, index) => (
                  <div className={`px-2 ${index === currentAnswerIndex ? 'pb-24' : 'pb-3'} mb-3`} key={index}> {/* Conditional padding */}
                    <ExpandableSection
                      title={answer.answerObject.query}
                      onExpand={() => setCurrentAnswerIndex(index)}
                      isExpandedForce={index == currentAnswerIndex}
                      titleClass={`w-full flex items-center justify-center p-3 font-bold text-brandMaroon text-[1.2rem] font-['Manrope',_sans-serif] ${(index == currentAnswerIndex) ? 'border-b-2 border-gray-300' : 'gap-2 cursor-pointer hover:bg-gray-50 border border-gray-200 rounded-md'}`}
                    >
                      <div>
                        <div className="p-2 flex items-center gap-2">
                          <AddToEditorButton
                            answer={answer}
                            projectId={id!}
                            queryHash={query_hash}
                          />
                          <ShareButton queryHash={query_hash!} />
                        </div>
                        <AnswerView
                          answer={answer}
                          queryHash={query_hash!}
                          id={id!}
                          answerFilters={answerFilters}
                          onAnswerUpdate={handleAnswerUpdate}
                          answerIndex={index}
                        />
                      </div>
                    </ExpandableSection>
                  </div>
                ))}
              </div>
            }
            rightTitle="Sources"
            rightComponent={
              // Sources Panel for SplitView - needs internal scroll
              <div className="h-full overflow-auto">
                <div className="p-2 pb-24">
                  <AnswerTable
                    data={currentAnswerData.papersArraySorted}
                    unusedSources={unusedSources}
                    referencesIdNumbers={currentAnswerData.idNumbers}
                    query_hash={query_hash!}
                    projectId={id!}
                    pendingCitationId={pendingCitationId}
                    onCitationResolved={() => setPendingCitationId(null)}
                  />
                </div>
              </div>
            }
          />
        ) : (
          // Case 2: Render single centered answer panel when no sources
          <div className="pt-4 pb-24"> {/* Outer scroll handled by parent; removed h-full overflow-y-auto */}
            <div className="flex justify-center">
              <div className="w-full md:w-4/5 lg:w-3/4 xl:w-2/3">
                {reviewData && reviewData.answers
                  // Removed filter to show all answers, not just the current one
                  .map((answer, index) => (
                    <div className="bg-white rounded-lg mb-3 shadow-sm hover:shadow-md transition-shadow duration-200" key={index}> {/* Reduced mb-6 to mb-3, added shadow effects */}
                      <div className={`px-2 ${index === currentAnswerIndex ? 'pb-6' : 'pb-2'}`}> {/* Conditional padding */}
                        <ExpandableSection
                          title={answer.answerObject.query}
                          onExpand={() => setCurrentAnswerIndex(index)}
                          isExpandedForce={index === currentAnswerIndex}
                          titleClass={`w-full flex items-center p-3 font-bold text-brandMaroon text-[1.2rem] font-['Manrope',_sans-serif] ${(index === currentAnswerIndex) ? 'border-b-2 border-gray-300' : 'justify-center gap-2 cursor-pointer hover:bg-gray-50 border border-gray-200 rounded-md'}`}
                        >
                          <div>
                            <div className="p-2 flex items-center gap-2">
                              <AddToEditorButton
                                answer={answer}
                                projectId={id!}
                                queryHash={query_hash}
                              />
                              <ShareButton queryHash={query_hash!} />
                            </div>
                            <AnswerView
                              answer={answer}
                              queryHash={query_hash!}
                              id={id!}
                              answerFilters={answerFilters}
                              onAnswerUpdate={handleAnswerUpdate}
                              answerIndex={index}
                            />
                          </div>
                        </ExpandableSection>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const renderLoadingScreen = () => {
    // Get query info from the queryStatus
    const isNewQuery = queryStatus?.isNew ?? false;
    const queryType = queryStatus?.type;
    const isDeepResearch = queryType === 'deep_research';
    const displayQuery = query || (reviewData?.answers[0]?.answerObject.query) || "";

    if (isNewQuery) {
      // When sending a new query, show full loading screen
      return <Loading question={displayQuery} extraLong={isDeepResearch} />;
    } else {
      // When loading an existing result, show a simpler loading screen
      return <SimpleLoading />;
    }
  };

  return (
    <>
      <SeoComp />
      {isLoading ? (
        renderLoadingScreen()
      ) : (
        <div className="h-screen-header overflow-y-auto overflow-x-hidden font-['Manrope',sans-serif] relative z-0">
          <Toaster closeButton expand={false} richColors position="top-center" />
          {/* <Feedback queryHash={query_hash!} /> */}
          {renderContent()}
          <div className="relative">
            <div ref={askNewQuestionRef} className=" fixed bottom-0 left-1/2 -translate-x-1/2 flex justify-center items-center pointer-events-none w-[90%] md:w-[70%] z-50" >
              {reviewData && (
                <div className="w-full pointer-events-auto">
                  <div className=" p-4 rounded-t-lg overflow-hidden ">
                    <AskNewQuestion
                      handleNewQuestionSubmit={handleNewQuestionSubmit}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AnswerThisAnswer;
