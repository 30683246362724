import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, NavigateFunction } from 'react-router-dom';
import { MessageSquare, FileUp, BarChart2, Library, Mail, PlayCircle, BookOpen, Search, Diamond, FileText, HelpCircle, ExternalLink, ChevronDown } from 'lucide-react';
import { toast } from 'sonner';
import { handleChatClick, uploadPapersToProject } from '../../authService';
import SeoComp from '../../components/SeoComp';
import { useSidebar } from '../../contexts/sidebarContext';
import { useSearch } from '../../contexts/queryContext';
import LiteratureReview from './LiteratureReview';
import { useAuth } from '../../contexts/AuthContext';
import Spinner from '../../components/Spinner';
import { showDemoIframe } from '../../components/editor/DocumentHistory';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import Typewriter from 'typewriter-effect';

interface AdditionalResourcesProps {
    id: string;
    navigate: NavigateFunction;
}

const AdditionalResources: React.FC<AdditionalResourcesProps> = ({
    id,
    navigate,
}) => (
    <div className="grid md:grid-cols-2 gap-8 mt-16">
        <div className="bg-white p-6 rounded-xl shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
                <PlayCircle className="mr-2 text-brandMaroon" size={24} />
                Tutorial Video
            </h3>
            <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden">
                <iframe
                    src="https://www.youtube.com/embed/AlUAdYt9vDc"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full"
                ></iframe>
            </div>
        </div>
        <div className="bg-white p-6 rounded-xl shadow">
            <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
                <Mail className="mr-2 text-brandMaroon" size={24} />
                Contact Us
            </h3>
            <p className="mb-4 text-gray-600">Have questions or need assistance? We're here to help!</p>
            <button
                className="bg-brandMaroon text-white px-6 py-2 rounded-lg inline-flex items-center hover:bg-red-700 transition-all duration-300"
                onClick={() => navigate(`/${id}/contact-us`)}
            >
                <Mail className="mr-2" size={18} />
                Contact Support
            </button>
        </div>
    </div>
);

interface ProFeaturesProps {
    userLevel: string;
    navigate: NavigateFunction;
    id: string;
}

const ProFeatures: React.FC<ProFeaturesProps> = ({ userLevel, navigate, id }) => (
    <div className="bg-gradient-to-r from-brandMaroon to-red-800 rounded-xl p-8 text-white shadow-lg mt-16">
        <div className="max-w-4xl mx-auto">
            <h3 className="text-2xl font-bold mb-4 text-white">
                {userLevel === 'paid' ? "Welcome Pro User" : "Elevate Your Research Game"}
            </h3>
            <p className="mb-6 text-gray-100">
                {userLevel === 'paid'
                    ? "Thank you for your support. Enjoy all the premium features of AnswerThis!"
                    : "Unlock the full potential of your research with our premium features."}
            </p>
            <div className="grid md:grid-cols-3 gap-6 mb-8">
                <div className="bg-white/10 p-4 rounded-lg backdrop-blur-sm">
                    <h4 className="font-semibold text-lg mb-2">Unlimited Access</h4>
                    <p className="text-sm text-gray-100">Explore our entire database without limits</p>
                </div>
                <div className="bg-white/10 p-4 rounded-lg backdrop-blur-sm">
                    <h4 className="font-semibold text-lg mb-2">Priority Support</h4>
                    <p className="text-sm text-gray-100">Get expert help whenever you need it</p>
                </div>
                <div className="bg-white/10 p-4 rounded-lg backdrop-blur-sm">
                    <h4 className="font-semibold text-lg mb-2">Advanced Features</h4>
                    <p className="text-sm text-gray-100">Access cutting-edge research tools</p>
                </div>
            </div>
            {userLevel !== 'paid' && (
                <button className="bg-white text-brandMaroon px-8 py-3 rounded-lg text-lg font-semibold hover:bg-gray-100 transition-all duration-300"
                    onClick={() => navigate(`/${id}/pricing`)}
                >
                    Upgrade to Pro
                </button>
            )}
        </div>
    </div>
);

interface ToolCardProps {
    icon: React.ElementType;
    title: string;
    description: string;
    onClick?: () => void;
    iconColor?: string;
    demoUrl?: string;
}

const ToolCard: React.FC<ToolCardProps> = ({
    icon: Icon,
    title,
    description,
    onClick,
    iconColor = "text-brandMaroon",
    demoUrl
}) => (
    <div
        onClick={onClick}
        className="bg-white p-5 rounded-xl shadow hover:shadow-md transition-all duration-300 flex items-start gap-4 relative cursor-pointer"
    >
        <div className={`${iconColor} mt-1`}>
            <Icon size={24} />
        </div>
        <div>
            <h4 className="font-semibold text-base mb-1 text-gray-800">{title}</h4>
            <p className="text-gray-600 text-sm">{description}</p>
        </div>
        {demoUrl && (
            <button
                className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                onClick={(e) => {
                    e.stopPropagation();
                    showDemoIframe(demoUrl);
                }}
            >
                <HelpCircle size={16} />
            </button>
        )}
    </div>
);

interface GuidesLinkProps {
    id: string;
}

const GuidesLink: React.FC<GuidesLinkProps> = ({ id }) => {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => navigate(`/${id}/guides`)}
            className="flex items-center text-gray-600 hover:text-brandMaroon gap-1 text-sm"
        >
            <ExternalLink size={14} />
            <span>View interactive guides</span>
        </button>
    );
};

interface ChatWithPDFModalProps {
    isOpen: boolean;
    onClose: () => void;
    handleLibraryClick: () => void;
    isUploading: boolean;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChatWithPDFModal: React.FC<ChatWithPDFModalProps> = ({
    isOpen,
    onClose,
    handleLibraryClick,
    isUploading,
    handleFileUpload
}) => (
    <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
            <DialogHeader>
                <DialogTitle>Chat with PDF</DialogTitle>
            </DialogHeader>
            <div className="text-center p-4">
                <MessageSquare className="mx-auto mb-4 text-brandMaroon" size={48} />
                <p className="text-gray-600 mb-6 text-sm">
                    Upload your documents and get instant answers to your questions.
                </p>
                <div className="flex flex-col sm:flex-row justify-center sm:space-x-4 space-y-4 sm:space-y-0">
                    <label
                        htmlFor="chat-popup-file-upload"
                        className="cursor-pointer bg-brandMaroon text-white px-6 py-2 rounded-lg inline-flex items-center justify-center hover:bg-red-700 transition-colors duration-200 w-full sm:w-auto"
                    >
                        <FileUp className="mr-2" size={18} />
                        {isUploading ? 'Uploading...' : 'Upload PDF'}
                    </label>
                    <button
                        onClick={() => {
                            handleLibraryClick();
                            onClose();
                        }}
                        className="bg-gray-200 text-gray-800 px-6 py-2 rounded-lg inline-flex items-center justify-center hover:bg-gray-300 transition-colors duration-200 w-full sm:w-auto"
                    >
                        <Library className="mr-2" size={18} />
                        Select from Library
                    </button>
                </div>
                <input
                    id="chat-popup-file-upload"
                    type="file"
                    multiple
                    accept=".pdf"
                    onChange={(e) => {
                        handleFileUpload(e);
                        onClose();
                    }}
                    className="hidden"
                />
            </div>
        </DialogContent>
    </Dialog>
);

interface ExtractDataModalProps {
    isOpen: boolean;
    onClose: () => void;
    setShowExtractData: (show: boolean) => void;
    handleLibraryClick: () => void;
    isUploading: boolean;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ExtractDataModal: React.FC<ExtractDataModalProps> = ({
    isOpen,
    onClose,
    setShowExtractData,
    handleLibraryClick,
    isUploading,
    handleFileUpload
}) => (
    <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-md">
            <DialogHeader>
                <DialogTitle>Extract Data from PDFs</DialogTitle>
            </DialogHeader>
            <div className="text-center p-4">
                <BarChart2 className="mx-auto mb-4 text-brandMaroon" size={48} />
                <p className="text-gray-600 mb-6 text-sm">
                    Automatically pull out key information and statistics from your documents.
                </p>
                <div className="flex flex-col sm:flex-row justify-center sm:space-x-4 space-y-4 sm:space-y-0">
                    <label
                        htmlFor="extract-popup-file-upload"
                        className="cursor-pointer bg-brandMaroon text-white px-6 py-2 rounded-lg inline-flex items-center justify-center hover:bg-red-700 transition-colors duration-200 w-full sm:w-auto"
                    >
                        <FileUp className="mr-2" size={18} />
                        {isUploading ? 'Uploading...' : 'Upload PDF'}
                    </label>
                    <button onClick={() => {
                        setShowExtractData(true);
                        handleLibraryClick();
                        onClose();
                    }}
                        className="bg-gray-200 text-gray-800 px-6 py-2 rounded-lg inline-flex items-center justify-center hover:bg-gray-300 transition-colors duration-200 w-full sm:w-auto"
                    >
                        <Library className="mr-2" size={18} />
                        Select from Library
                    </button>
                </div>
                <input
                    id="extract-popup-file-upload"
                    type="file"
                    multiple
                    accept=".pdf"
                    onChange={(e) => {
                        handleFileUpload(e);
                        onClose();
                    }}
                    className="hidden"
                />
            </div>
        </DialogContent>
    </Dialog>
);

const HomeUpload: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('review');
    const [query, setQuery] = useState<string>('');
    const { id } = useParams();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [showChatPopup, setShowChatPopup] = useState<boolean>(false);
    const [showExtractPopup, setShowExtractPopup] = useState<boolean>(false);
    const navigate = useNavigate();
    const { setShowExtractData } = useSearch();
    const { refreshSidebar } = useSidebar();
    const { user, checkCredits, removeCredits, isLoading } = useAuth();

    // Maintain the existing useEffect for tab handling
    useEffect(() => {
        handleTabChange('review');
    }, []);

    const handleTabChange = (tab: string) => {
        setActiveTab(tab);
    };

    if (isLoading || !user) {
        return <Spinner />;
    }

    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const loadingToastId = toast.loading('Uploading papers...');
            const hasEnoughCredits = checkCredits(id!, files.length);
            if (!hasEnoughCredits) {
                return;
            }
            setIsUploading(true);

            try {
                const paperUpload = await uploadPapersToProject(
                    id!,
                    files,
                    (message) => {
                        removeCredits(files.length);
                        toast.dismiss(loadingToastId);
                        toast.success(message);
                        refreshSidebar();
                    },
                    (error) => {
                        toast.dismiss(loadingToastId);
                        toast.error(error);
                    },
                    navigate,
                    setIsUploading
                );

                if (paperUpload) {
                    if (activeTab === 'chat') {
                        await handleChatClick(
                            paperUpload.paperIds,
                            id!,
                            navigate,
                            (response) => { toast.success(response) },
                            (error) => { toast.error(error) },
                        );
                    } else {
                        setShowExtractData(true);
                        navigate(`/${id}/ask-answerthis?tab=library`);
                    }
                }
            } catch (error) {
                toast.dismiss(loadingToastId);
                if (error instanceof Error) {
                    toast.error(error.message);
                } else {
                    toast.error("An unexpected error occurred while uploading files");
                }
            } finally {
                setIsUploading(false);
            }
        }
    };

    const handleLibraryClick = () => {
        navigate(`/${id}/ask-answerthis?tab=library`);
    };

    // Demo URLs for tools
    const DEMO_URLS = {
        diagramGenerator: "https://app.supademo.com/embed/cm7nuad5c0f8ti0nhr4dgvykf?embed_v=2",
        citationMap: "https://app.supademo.com/embed/cm7nsxbvy0duoi0nhap527qke?embed_v=2",
        searchPapers: "https://app.supademo.com/embed/cm7nslxtd0dgti0nhdme9jun4?embed_v=2",
        library: "https://app.supademo.com/embed/cm7nsa1h30czmi0nh3rwlgnke?embed_v=2",
        chatWithPaper: "https://app.supademo.com/embed/cm7nyjj1z004khilgmzuz1dgj?embed_v=2",
        literatureReview: "https://app.supademo.com/embed/cm7nzuc4i00l7hilgfn3ynvy5?embed_v=2",
        editor: "https://app.supademo.com/embed/cm7mbj5en1w0cddumsngnkr1a?embed_v=2"
    };

    return (
        <div>
            <SeoComp />
            <div className="min-h-screen flex flex-col">
                {/* Hero Section with Typewriter and Search Bar */}
                <div className="min-h-screen-header-tabs flex flex-col items-center justify-center px-4 sm:px-6 w-full">
                    {/* Typewriter above search bar */}
                    <HeadUploadTypewriter />

                    {/* Centered search bar */}
                    <div className="w-full max-w-5xl mx-auto relative">
                        <div className="my-auto">
                            <LiteratureReview
                                showOnlySearchBar={true}
                                onQueryChange={setQuery}
                                initialQuery={query}
                            />
                        </div>
                        <div className="absolute top-2 right-2 z-10">
                            <button
                                onClick={() => showDemoIframe(DEMO_URLS.literatureReview)}
                                className="text-gray-400 hover:text-gray-600 transition-colors duration-200 h-8 w-8 flex items-center justify-center"
                            >
                                <HelpCircle size={18} />
                            </button>
                        </div>
                    </div>

                    {/* Scroll indicator */}
                    <div className="animate-bounce cursor-pointer mt-20" onClick={() => document.getElementById('tools-section')?.scrollIntoView({ behavior: 'smooth' })}>
                        <div className="flex flex-col items-center">
                            <span className="text-sm text-gray-500 mb-2">Explore Tools</span>
                            <ChevronDown className="text-brandMaroon" />
                        </div>
                    </div>
                </div>

                {/* Tools Sections */}
                <div id="tools-section" className="max-w-6xl mx-auto px-4 sm:px-6 py-10">
                    {/* Discovery Tools - Shows Best for Researchers First */}
                    <div className="mb-12">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-semibold text-gray-800">Best for Researchers</h2>
                            <GuidesLink id={id!} />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            <ToolCard
                                icon={BookOpen}
                                title="Literature Review"
                                description="Discover new papers for your research."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=review`)}
                                iconColor="text-amber-500"
                                demoUrl={DEMO_URLS.literatureReview}
                            />
                            <ToolCard
                                icon={Search}
                                title="Search Papers"
                                description="Discover topics from 285M research papers."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=search`)}
                                iconColor="text-cyan-500"
                                demoUrl={DEMO_URLS.searchPapers}
                            />
                            <ToolCard
                                icon={MessageSquare}
                                title="Citation Map"
                                description="Visualize citation networks and research connections."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=citation-map`)}
                                iconColor="text-indigo-500"
                                demoUrl={DEMO_URLS.citationMap}
                            />
                        </div>
                    </div>

                    {/* Popular Tools - Moved Down */}
                    <div className="mb-12">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-semibold text-gray-800">Popular Tools</h2>
                            {user.level !== 'paid' && (
                                <button
                                    onClick={() => navigate(`/${id}/pricing`)}
                                    className="text-sm bg-white border border-brandMaroon text-brandMaroon px-4 py-1.5 rounded-lg hover:bg-gray-50 transition-all duration-300 flex items-center"
                                >
                                    <Diamond className="mr-1" size={16} />
                                    Get Pro Access
                                </button>
                            )}
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                            <ToolCard
                                icon={MessageSquare}
                                title="Chat with PDF"
                                description="Get all answers backed by citations."
                                onClick={() => setShowChatPopup(true)}
                                iconColor="text-pink-500"
                                demoUrl={DEMO_URLS.chatWithPaper}
                            />
                            <ToolCard
                                icon={FileText}
                                title="AI Writer"
                                description="Write new research papers with AI."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=documents`)}
                                iconColor="text-blue-500"
                                demoUrl={DEMO_URLS.editor}
                            />
                            <ToolCard
                                icon={BarChart2}
                                title="Extract Data"
                                description="Get summaries from multiple PDFs."
                                onClick={() => setShowExtractPopup(true)}
                                iconColor="text-purple-500"
                            />
                            <ToolCard
                                icon={Search}
                                title="Diagram Generator"
                                description="Create visual diagrams automatically."
                                onClick={() => navigate(`/${id}/ask-answerthis?tab=diagram`)}
                                iconColor="text-green-500"
                                demoUrl={DEMO_URLS.diagramGenerator}
                            />
                        </div>
                    </div>

                    {/* Pro Features */}
                    <ProFeatures userLevel={user.level} navigate={navigate} id={id!} />

                    {/* Additional Resources */}
                    <AdditionalResources id={id!} navigate={navigate} />
                </div>
            </div>

            {/* Modals - Keeping the original modal functionality */}
            <ChatWithPDFModal
                isOpen={showChatPopup}
                onClose={() => setShowChatPopup(false)}
                handleLibraryClick={handleLibraryClick}
                isUploading={isUploading}
                handleFileUpload={handleFileUpload}
            />

            <ExtractDataModal
                isOpen={showExtractPopup}
                onClose={() => setShowExtractPopup(false)}
                setShowExtractData={setShowExtractData}
                handleLibraryClick={handleLibraryClick}
                isUploading={isUploading}
                handleFileUpload={handleFileUpload}
            />
        </div>
    );
};

function HeadUploadTypewriter() {
    return (
        <div className="text-center">
            <h1 className="text-2xl md:text-4xl font-extrabold text-gray-900 mb-10 leading-tight">
                <span className="text-brandMaroon hidden md:block">
                    <Typewriter
                        options={{
                            strings: ['Comprehensive Literature Reviews', 'Research Gaps', 'Research Proposals', 'Project Reports'],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </span>
                <span className="text-brandMaroon block md:hidden">Comprehensive Literature Reviews</span>
                {" "}In Seconds
            </h1>

        </div>
    );
}

export default HomeUpload;