import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Loader2, AlertCircle, ExternalLink, FileText, Quote, LogIn, Check } from 'lucide-react';
import SeoComp from '../components/SeoComp';
import { Button } from '@/components/ui/button';
import axios from 'axios';
import { serverUrl, AnswerObject, SourceInfo, handleRedirectMainPage } from '@/authService';
import { Toaster } from 'sonner';
import "./Answer/AnswerThisAnswer.css";
import "./SharedLiteratureReview.css";
import parse from 'html-react-parser';
import { marked } from 'marked';
import CollapsibleReferences from '../components/AnswerTable/CollapsibleReferences';
import CitationManager from '../pages/Answer/CitationManager';
import { citationStyles, getInTextCitation, CITATION_STYLES } from './Answer/citationUtils';
import { useAuth } from '@/contexts/AuthContext';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
} from "@/components/ui/select";

interface ReviewData {
    query_text: string;
    answer: AnswerObject;
    created_at: string;
    shared_by: {
        id: number;
        name?: string;
    };
}

const SharedLiteratureReview: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [reviewData, setReviewData] = useState<ReviewData | null>(null);
    const [citationStyle, setCitationStyle] = useState<citationStyles>('chicago');
    const [parsedAnswer, setParsedAnswer] = useState<string>('');
    const [activeTab, setActiveTab] = useState<'answer' | 'sources'>('answer');
    const isLoggedIn = !!user;

    // Handle window resize for responsive design
    useEffect(() => {
        const handleResize = () => {
            // Remove the isMobile state update but keep the handler for future use if needed
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchSharedReview = async () => {
            try {
                const response = await axios.get(`${serverUrl}/reviews/shared/${slug}`, {
                    withCredentials: true
                });
                console.log("API Response:", response.data);

                if (response.status !== 200) {
                    throw new Error(response.data.error || 'Failed to load shared review');
                }

                setReviewData(response.data);
            } catch (err) {
                console.error("Error fetching review:", err);
                setError(err instanceof Error ? err.message : 'An error occurred');
            } finally {
                setLoading(false);
            }
        };

        fetchSharedReview();
    }, [slug]);

    // Handles citation clicks that navigate to the sources view
    const handleCitationClick = useCallback((e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const isLink = target.tagName === 'A' && target.getAttribute('href')?.startsWith('#');

        if (isLink) {
            e.preventDefault();
            setActiveTab('sources');

            const id = target.getAttribute('href')!.substring(1);
            setTimeout(() => {
                const targetElement = document.getElementById(id);
                if (targetElement) {
                    targetElement.scrollIntoView({ behavior: 'smooth' });
                    document.querySelectorAll('.selected').forEach((el) => {
                        el.classList.remove('selected');
                    });
                    targetElement.classList.add('selected');
                }
            }, 100);
        }
    }, []);

    // Add citation click handler
    useEffect(() => {
        document.addEventListener('click', handleCitationClick);
        return () => {
            document.removeEventListener('click', handleCitationClick);
        };
    }, [handleCitationClick]);

    // Helper function to remove references section from the answer
    const removeReferences = (text: string): string => {
        const parts = text.split('References:');
        return parts[0].trim();
    };

    // Handler to update the answer if needed (for CitationManager)
    const handleAnswerUpdate = (updatedAnswer: string) => {
        if (reviewData) {
            setReviewData({
                ...reviewData,
                answer: {
                    ...reviewData.answer,
                    answer: updatedAnswer
                }
            });
        }
    };

    // Function to process markdown in the answer
    const processMarkdown = async (text: string): Promise<string> => {
        if (!text) return '';

        // Fix newlines for markdown
        const processedText = text.replace(/\n(?=\n)/g, "\n\n");

        try {
            // Convert markdown to HTML with simple options
            const markedText = await marked(processedText);
            return markedText;
        } catch (error) {
            console.error("Error parsing markdown:", error);
            return text;
        }
    };

    // Function to apply in-text citations based on the current citation style
    const applyInTextCitations = (text: string, sources: SourceInfo[]): string => {
        if (!text || !sources || sources.length === 0) return text;

        let modifiedText = text;
        sources.forEach((source, index) => {
            const sourceId = source.id;
            const citation = getInTextCitation(source, citationStyle, index);
            const regexPattern = new RegExp(`\\[chunk_${sourceId}\\]`, 'g');
            modifiedText = modifiedText.replace(regexPattern, `<a href="#${sourceId}" class="citation">${citation}</a>`);
        });

        return modifiedText;
    };

    // Prepare answer with appropriate citations and markdown
    const prepareAnswer = useCallback(async () => {
        if (!reviewData || !reviewData.answer) return '';

        const sources = reviewData.answer.sources
            ? Object.values(reviewData.answer.sources.result)
            : [];

        // First process markdown
        const markdownProcessed = await processMarkdown(removeReferences(reviewData.answer.answer));

        // Then apply citations
        const withCitations = applyInTextCitations(markdownProcessed, sources);

        // Update state with processed answer
        setParsedAnswer(withCitations);
    }, [reviewData, citationStyle]);

    // Effect to prepare the answer when review data or citation style changes
    useEffect(() => {
        if (reviewData) {
            prepareAnswer();
        }
    }, [reviewData, citationStyle, prepareAnswer]);



    if (loading) {
        return (
            <div className="min-h-screen flex items-center justify-center">
                <Loader2 className="w-10 h-10 animate-spin text-brandMaroon" />
                <span className="ml-2 text-lg text-gray-700">Loading shared literature review...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div className="min-h-screen flex flex-col items-center justify-center p-4">
                <div className="bg-red-50 p-6 rounded-lg max-w-md w-full">
                    <div className="flex items-center mb-4">
                        <AlertCircle className="w-8 h-8 text-red-500 mr-2" />
                        <h2 className="text-xl font-semibold text-red-700">Error</h2>
                    </div>
                    <p className="text-gray-700 mb-4">{error}</p>
                    <Link to="/">
                        <Button className="w-full bg-brandMaroon hover:bg-red-700">
                            Go to Homepage
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }

    // Prepare answer data for rendering
    const answerData = reviewData ? {
        formattedAnswer: reviewData.answer.answer,
        answerObject: reviewData.answer,
        papersArraySorted: reviewData.answer.sources ? Object.values(reviewData.answer.sources.result) : []
    } : null;

    // Define Citation Style Selector component
    const CitationStyleSelector = () => (
        <div className="flex items-center">
            <Select
                value={citationStyle}
                onValueChange={(value) => setCitationStyle(value as citationStyles)}
            >
                <SelectTrigger
                    className="flex items-center px-3 py-2 bg-white border border-gray-200 text-gray-700 rounded-md hover:bg-gray-50 transition w-auto h-auto"
                >
                    <Quote className="w-4 h-4 mr-2" />
                    <span className="text-sm">Style: {citationStyle.toUpperCase()}</span>
                </SelectTrigger>
                <SelectContent>
                    {CITATION_STYLES.map(style => (
                        <SelectItem key={style} value={style}>
                            {style.toUpperCase()}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        </div>
    );

    return (
        <>
            <SeoComp />
            <div className="min-h-screen flex flex-col bg-gradient-to-br from-white to-purple-50">
                <div className="flex flex-col md:flex-row flex-1">
                    {/* Main Content Area */}
                    <div className="flex-1 w-full md:w-3/4 p-6 pt-24">
                        <div className="max-w-5xl mx-auto">
                            {/* Header and Navigation */}
                            <div className="mb-8">
                                <h1 className="text-3xl md:text-4xl font-bold mb-4 text-gray-900 leading-tight">
                                    {reviewData?.query_text}
                                </h1>

                                <div className="flex flex-col md:flex-row md:items-center justify-between mb-6 gap-4">
                                    <div className="text-sm text-gray-500">
                                        <span>Shared on {reviewData?.created_at && new Date(reviewData.created_at).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })}</span>
                                        {reviewData?.shared_by?.name && (
                                            <span className="ml-2">
                                                <span className="mx-2">•</span>
                                                <span>{reviewData.shared_by.name}</span>
                                            </span>
                                        )}
                                    </div>
                                    <CitationStyleSelector />
                                </div>

                                {/* Tabs */}
                                <div className="border-b border-gray-200 mb-6">
                                    <div className="flex -mb-px">
                                        <button
                                            onClick={() => setActiveTab('answer')}
                                            className={`mr-4 py-2 px-1 font-medium ${activeTab === 'answer'
                                                ? 'text-brandMaroon border-b-2 border-brandMaroon'
                                                : 'text-gray-500 hover:text-gray-700'
                                                }`}
                                        >
                                            Literature Review
                                        </button>
                                        <button
                                            onClick={() => setActiveTab('sources')}
                                            className={`py-2 px-1 font-medium ${activeTab === 'sources'
                                                ? 'text-brandMaroon border-b-2 border-brandMaroon'
                                                : 'text-gray-500 hover:text-gray-700'
                                                }`}
                                        >
                                            Sources ({answerData?.papersArraySorted.length || 0})
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Content */}
                            <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6 mb-12">
                                {activeTab === 'answer' && answerData && (
                                    <div className="prose max-w-none text-[0.9rem] leading-7">
                                        {parse(parsedAnswer)}
                                        <CollapsibleReferences answer={answerData.formattedAnswer} />
                                        <div className="mt-8">
                                            <CitationManager
                                                sources={answerData.papersArraySorted}
                                                answer={answerData.formattedAnswer}
                                                onAnswerUpdate={handleAnswerUpdate}
                                            />
                                        </div>
                                    </div>
                                )}

                                {activeTab === 'sources' && answerData && (
                                    <div>
                                        {answerData.papersArraySorted.length > 0 ? (
                                            <div className="space-y-4">
                                                {answerData.papersArraySorted.map((source, index) => (
                                                    <div
                                                        key={source.id}
                                                        id={source.id}
                                                        className="paper-section p-4 bg-white rounded-lg border border-gray-200 hover:border-brandMaroon transition-colors"
                                                    >
                                                        <div className="flex items-start space-x-3">
                                                            <div className="h-6 w-6 flex-shrink-0 bg-brandMaroon text-white rounded-full flex items-center justify-center text-sm font-medium">
                                                                {index + 1}
                                                            </div>
                                                            <div className="flex-1">
                                                                <h3 className="font-medium text-gray-900">{source.title}</h3>
                                                                <p className="text-sm text-gray-600 mt-1">{source.author}</p>
                                                                <div className="text-sm text-gray-600 mt-2 space-y-1">
                                                                    {source.journal && <p><span className="font-medium">Journal:</span> {source.journal}</p>}
                                                                    {source.date_of_publication && <p>
                                                                        <span className="font-medium">Year:</span> {new Date(source.date_of_publication).getFullYear()}
                                                                    </p>}
                                                                    {source.citations && <p>
                                                                        <span className="font-medium">Citations:</span> {source.citations}
                                                                    </p>}
                                                                </div>
                                                                {source.url && (
                                                                    <a
                                                                        href={source.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="mt-2 inline-block text-sm flex items-center text-blue-600 hover:text-blue-800"
                                                                    >
                                                                        View source <ExternalLink className="w-3 h-3 ml-1" />
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="text-center py-8 text-gray-500">
                                                <FileText className="mx-auto h-8 w-8 text-gray-400 mb-2" />
                                                <p>No source details available</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Sidebar */}
                    <div className="w-full md:w-1/4 md:min-w-72 bg-white border-l border-gray-200 p-24 sticky top-0 h-screen overflow-auto">
                        <div className="flex flex-col h-full justify-between">
                            <div>
                                <div className="mb-8">
                                    <div className="mb-6 text-center">

                                    </div>

                                    {isLoggedIn ? (
                                        <div className="space-y-4">
                                            <Button
                                                onClick={() => handleRedirectMainPage(navigate)}
                                                className="w-full bg-brandMaroon font-semibold"
                                            >
                                                Create new review
                                            </Button>
                                        </div>
                                    ) : (
                                        <div className="space-y-3">
                                            <Button
                                                onClick={() => navigate('/login')}
                                                className="w-full bg-brandMaroon hover:bg-red-700 font-semibold"
                                            >
                                                <LogIn className="mr-2 h-4 w-4" /> Sign In
                                            </Button>
                                            <Button
                                                onClick={() => navigate('/signup')}
                                                variant="outline"
                                                className="w-full border-brandMaroon text-brandMaroon hover:bg-red-50 font-semibold"
                                            >
                                                Create Free Account
                                            </Button>
                                        </div>
                                    )}
                                </div>

                                <div className="border-t border-gray-200 pt-6">



                                    <div className="space-y-3">
                                        <h4 className="font-medium text-gray-900 mb-1">Why researchers choose us:</h4>
                                        <div className="flex items-start space-x-2">
                                            <Check className="h-4 w-4 text-green-500 mt-0.5 flex-shrink-0" />
                                            <span className="text-sm text-gray-700">Comprehensive reviews in minutes, not weeks</span>
                                        </div>
                                        <div className="flex items-start space-x-2">
                                            <Check className="h-4 w-4 text-green-500 mt-0.5 flex-shrink-0" />
                                            <span className="text-sm text-gray-700">Only uses peer-reviewed sources</span>
                                        </div>
                                        <div className="flex items-start space-x-2">
                                            <Check className="h-4 w-4 text-green-500 mt-0.5 flex-shrink-0" />
                                            <span className="text-sm text-gray-700">APA, MLA, Chicago citation styles</span>
                                        </div>
                                        <div className="flex items-start space-x-2">
                                            <Check className="h-4 w-4 text-green-500 mt-0.5 flex-shrink-0" />
                                            <span className="text-sm text-gray-700">Export to Word, PDF, or share online</span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <Toaster closeButton expand={false} richColors position="top-center" />
            </div>
        </>
    );
};

export default SharedLiteratureReview;